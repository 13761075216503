import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IOrder } from 'src/app/models/orders.models';
import { OrderService } from 'src/app/services/order.service';




@Component({
  selector: 'unifyshipmenticon',
  styleUrls: ['./unifyshipmenticon.usercontrol.component.css'],
  templateUrl: './unifyshipmenticon.usercontrol.component.html'
})
export class UnifyShipmentIconUserControlComponent implements OnChanges {

  constructor(
    private _orderService: OrderService
  ) { }

  @Input() UnifyShipmentId!: string;
  @Input() OrderId!: string;

  public Orders: IOrder[] | null;
  public LoadingOrders = false;
  ngOnChanges(changes: SimpleChanges): void {
    this.Orders = null;
  }

  public async OpenOrdersToolTip(tooltip) {

    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      if (!this.Orders) {
        this.LoadingOrders = true;
        this.Orders = (await this._orderService.SearchOrders({
          ShipmentIds: [this.UnifyShipmentId],
          ExcludeIds: [this.OrderId],
          ProjectionFields: ["Id", "SupplierNumber"],
          PageSize: 10
        }).toPromise()).Result.Items;


        this.LoadingOrders = false;
      }
      tooltip.open();

    }
  }

}
