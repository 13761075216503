<ng-container *ngIf="Shipment">
  <!-- view mode -->

  <div *ngIf="Mode==1" class="container-fluid p-0 m-0">
    <div class="row row-compact" style="height:36px; padding-right:7px; padding-top:3px;">
      <div class="col-12 d-flex justify-content-between">
        <div>{{Shipment.Recipient.FullName}}</div>
        <div>
          <span class="badge badge-secondary">
            <ng-container *ngIf="Shipment.PackagesCount == 1;else MultiPackages">
              חבילה אחת
            </ng-container>
            <ng-template #MultiPackages>
              {{Shipment.PackagesCount}} חבילות
            </ng-template>
          </span>
        </div>
      </div>
    </div>
    <div class="row row-compact" style="height:36px; padding-right:7px; padding-top:3px;">
      <div class="col-5 ltr">
        {{Shipment.Recipient.Phone}}
      </div>

      <div class="col-5 mlll-auto" *ngIf="Shipment.Recipient?.SecondaryPhone">
        מספר נוסף : <span dir="ltr">{{Shipment.Recipient.SecondaryPhone}}</span>
      </div>
    </div>


    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.Recipient?.Email">{{Shipment.Recipient?.Email}}</div>
    <address-editor [(Address)]="Shipment.Address" #AddressEditor [Mode]="AddressMode" (ValidationFromChange)="BindAddressFromControl($event)"></address-editor>
    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.TrackingNumber">מספר מעקב: {{Shipment.TrackingNumber}}</div>
    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.Remarks">הערות: {{Shipment.Remarks}}</div>
  </div>
  <!-- edit mode -->
  <div *ngIf="Mode==2" class="container-fluid p-0 m-0">
    
    <div class="row row-compact">
      <div class="col-9">
        <labeled-textbox title="שם לקוח" class="compact" [(model)]="Shipment.Recipient.FullName" (modelChange)="RaiseChange()" [bindFormControl]="ValidationFrom.controls.RecipientFullName" [validationMessages]="{required:'חובה להכניס שם לקוח'}"></labeled-textbox>
      </div>
      <div class="col-3">
        <labeled-textbox type="number" title="מספר חבילות" class="compact" [(model)]="Shipment.PackagesCount" (modelChange)="RaiseChange()" [bindFormControl]="ValidationFrom.controls.PackagesCount" [validationMessages]="{required:'חובה להכניס מספר חבילות',min:'מספר חבילות חייב להיות גדול מאפס'}"></labeled-textbox>
      </div>
    </div>
    <div class="row row-compact">
      <div class="col-5">
        <labeled-textbox type="tel" title="טלפון" class="compact" [(model)]="Shipment.Recipient.Phone" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>

      <div class="col-5 mlll-auto">
        <labeled-textbox type="tel" title=" טלפון נוסף" class="compact" [(model)]="Shipment.Recipient.SecondaryPhone" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
    </div>
    <div class="row row-compact">
      <div class="col-12">
        <labeled-textbox type="email" title="אימייל" class="compact" [(model)]="Shipment.Recipient.Email" (modelChange)="RaiseChange()" [bindFormControl]="ValidationFrom.controls.RecipientEmail" [validationMessages]="{email:'כתובת איימיל לא חוקית'}" ></labeled-textbox>
      </div>
    </div>
    <address-editor [(Address)]="Shipment.Address" [Mode]="AddressMode" (AddressChange)="RaiseChange()" (ValidationFromChange)="BindAddressFromControl($event)"></address-editor>
    <div class="row row-compact">
      <div class="col-12">
        <labeled-textbox title="מספר מעקב" class="compact" [(model)]="Shipment.TrackingNumber" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
    </div>
    <div class="row row-compact">
      <div class="col-12">
        <labeled-textbox title="הערות משלוח" class="compact" [(model)]="Shipment.Remarks" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
    </div>
  </div>

</ng-container>