<label class="form-group has-float-label text-right autocomplate-dropdown-menu-full-width">
  <input [type]="type" [id]="id" [name]="id"  class="form-control"
         [ngClass]="{'is-invalid' :  bindFormControl.invalid }" 
         placeholder=" "
         autofocus  tabindex="{{tabindex}}" 
         [formControl]="bindFormControl"
         #ngbTypeahead=ngbTypeahead 
         [ngbTypeahead]="CngbTypeaheadInternal" 
         [inputFormatter]="CinputFormatter" 
         [resultTemplate]="CresultTemplate" 
         (selectItem)="CselectItem.emit($event)"
         [placement]="Placement"
         (keydown)="showDropdownEle($event)"
         (focus)="focus$.next($any($event).target.value)"
         (click)="click$.next($any($event).target.value)"
         />
  <span class="label">{{title}}</span>
  <span [hidden]="! bindFormControl.invalid" class="invalid-feedback text-left">{{GetErrorMessage()}}</span>
  <i *ngIf="bindFormControl?.pending" class="fa fa-spinner fa-pulse fa-2x fa-fw float-label-loading"></i>
</label>
