<ng-container *ngIf="Address">
  <!-- view mode -->

  <div *ngIf="Mode % (2 * 1) >= 1" class="container-fluid p-0 m-0">

    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Address.Street">{{Address.Street}} <ng-container *ngIf="Address.HouseNum" >, {{Address.HouseNum}}</ng-container></div>
    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Address.Entrance || Address.Floor || Address.Apartment">
      <ng-container *ngIf="Address.Entrance"> כניסה {{Address.Entrance}}</ng-container>
      <ng-container *ngIf="Address.Floor"> קומה {{Address.Floor}}</ng-container>
      <ng-container *ngIf="Address.Apartment"> דירה {{Address.Apartment}}</ng-container>
    </div>
    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Address.POBox || Address.Zip || Address.Company">
      <ng-container *ngIf="Address.POBox">תא דואר {{Address.POBox}}</ng-container>
      <ng-container *ngIf="Address.Company"> חברה {{Address.Company}}</ng-container>
      <ng-container *ngIf="Address.Zip"> מיקוד {{Address.Zip}}</ng-container>
    </div>
    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Address.City"><ng-container *ngIf="Address.Neighborhood">שכונת {{Address.Neighborhood}}, </ng-container>{{Address.City}}
    </div>
    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Address.Remarks">הערות: {{Address.Remarks}}</div>

  </div>
  <!-- edit mode -->
  <div *ngIf="Mode % (2 * 2) >= 2" class="container-fluid p-0 m-0">

    <div class="row row-compact">
      <div class="col-6">
        <street-autocomplate-input title="רחוב" class="compact autocomplate-dropdown-menu-h150-scrollable" [(model)]="Address.Street" [City]="Address.City" (modelChange)="RaiseChange()"></street-autocomplate-input>
      </div>
      <div class="col-3">
        <labeled-textbox title="מספר" class="compact" [(model)]="Address.HouseNum" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>

    </div>
    <div class="row row-compact">
      <div class="col">
        <labeled-textbox title="כניסה" class="compact" [(model)]="Address.Entrance" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
      <div class="col">
        <labeled-textbox title="קומה" class="compact" [(model)]="Address.Floor" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
      <div class="col">
        <labeled-textbox title="דירה" class="compact" [(model)]="Address.Apartment" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
    </div>
    <div class="row row-compact">
      <div class="col">
        <labeled-textbox title="תא דואר" class="compact" [(model)]="Address.POBox" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
      <div class="col">
        <labeled-textbox title="חברה" class="compact" [(model)]="Address.Company" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
      <div class="col">
        <labeled-textbox title="מיקוד" class="compact" [(model)]="Address.Zip" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
    </div>
    <div class="row row-compact">
      <div class="col-9">
        <city-autocomplate-input title="עיר" class="compact autocomplate-dropdown-menu-h150-scrollable" [(model)]="Address.City" (modelChange)="RaiseChange()" [bindFormControl]="ValidationFrom?.controls?.AddressCity" [validationMessages]=" {required:'חובה להכניס עיר'}"></city-autocomplate-input>
      </div>
      <div class="col-3">
        <labeled-textbox title="שכונה" class="compact" [(model)]="Address.Neighborhood" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
    </div>

  </div>

</ng-container>