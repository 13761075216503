<div>
  <h1>תיקון כתובות</h1>
  <div class="d-flex justify-content-between">
    <h2 *ngIf="OrderShipmentErrorsCount"> נמצאו {{OrderShipmentErrorsCount}} הזמנות שדורשות טיפול</h2>
    <div>
      <button *ngIf="DidComeFromPrintPage" type="button" class="btn btn-warning mx-2" (click)="NavigateToPrintValidOnlyUrl();"> הדפס משלוחים תקינים בלבד <i class="fas fa-filter"></i></button>
      <button type="button" class="btn btn-primary" (click)="NavigateToReturnUrl();">{{!!ReturnDescription ? ReturnDescription : 'חזור' }}</button>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <pager *ngIf="Orders && Orders.PageSize < Orders.TotalItemsCount" [PageSize]="Query.PageSize" [PageIndex]="Query.PageIndex" (PageIndexChange)="Query.PageIndex = $event; LoadOrders();" [TotalItemCount]="Orders.TotalItemsCount"></pager>
  </div>
  <div *ngIf="Orders">
    <table class="table table-hover table-sm ">
      <thead>
        <tr>
          <th scope="col" class="" style="width: 8%;">מספר הזמנה</th>
          <th scope="col" class="" style="width: 8%;">שם מלא</th>
          <th scope="col" class="" style="width: 8%;">טלפון</th>
          <th scope="col" class="" style="width: 8%;">עיר</th>
          <th scope="col" class="" style="width: 8%;">רחוב</th>
          <th scope="col" class="" style="width: 4%;">בית</th>
          <th scope="col" class="" style="width: 4%;">כניסה</th>
          <th scope="col" class="" style="width: 4%;">קומה</th>
          <th scope="col" class="" style="width: 4%;">דירה</th>
          <th *ngIf="HasDropPointShipmentsType" scope="col" class="" style="width: 8%;">נק מסירה</th>
          <th scope="col" class="" [style.width]="HasDropPointShipmentsType ? '23%' : '31%'">הודעת שגיאה</th>
          <th scope="col" class="" style="width: 5%;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of Orders.Items" [@submitEffect]="GetShipmentSubmitState(order.Shipments[0])" (@submitEffect.done)="ClearShipmentSubmitState(order.Shipments[0])">
          <ng-container *ngIf="!IsInEditMode(order.Shipments[0]);else EditOrderRow">
            <td><a [routerLink]="['/order', order.Id]">{{order.SupplierNumber}}</a></td>
            <td>{{order.Shipments[0].Recipient.FullName}}</td>
            <td class="ltr">{{order.Shipments[0].Recipient.Phone}}</td>
            <td>{{$any(order.Shipments[0]).Address?.City}}</td>
            <td>{{$any(order.Shipments[0]).Address?.Street}}</td>
            <td>{{$any(order.Shipments[0]).Address?.HouseNum}}</td>
            <td>{{$any(order.Shipments[0]).Address?.Entrance}}</td>
            <td>{{$any(order.Shipments[0]).Address?.Floor}}</td>
            <td>{{$any(order.Shipments[0]).Address?.Apartment}}</td>
            <td *ngIf="HasDropPointShipmentsType">
              <span>{{$any(order.Shipments[0]).DropPoint?.Name}} </span>
              <span class="badge badge-light">{{$any(order.Shipments[0]).DropPoint?.Address?.City}}</span>
            </td>
            <td class="text-danger font-weight-bold">{{order.Shipments[0].ErrorMessage}}</td>
            <td class=""> <button class="btn btn-outline-primary btn-sm float-left" (click)="EditShipmnet(order.Shipments[0])" [disabled]="$any(EditShipments).hasOwnProperty(order.Shipments[0].Id)"><i [ngClass]="$any(EditShipments).hasOwnProperty(order.Shipments[0].Id) ? 'fa fa-spinner fa-pulse' : 'fas fa-pencil-alt'"></i>
                ערוך</button> </td>
          </ng-container>
          <ng-template #EditOrderRow>
            <!-- todo need to fill the edit row-->
            <td><a [routerLink]="['/order', order.Id]">{{order.SupplierNumber}}</a></td>
            <td>{{EditShipments[order.Shipments[0].Id].Recipient.FullName}}</td>
            <td class="ltr">{{EditShipments[order.Shipments[0].Id].Recipient.Phone}}</td>
            <ng-container *ngIf="$any(EditShipments[order.Shipments[0].Id]).Address">
              <td>
                <city-autocomplate-input class="no-margin" [(model)]="$any(EditShipments[order.Shipments[0].Id]).Address.City"></city-autocomplate-input>
              </td>
              <td>
                <street-autocomplate-input class="no-margin" [(model)]="$any(EditShipments[order.Shipments[0].Id]).Address.Street" [City]="$any(EditShipments[order.Shipments[0].Id]).Address.City"></street-autocomplate-input>
              </td>
              <td>
                <labeled-textbox class="no-margin" [(model)]="$any(EditShipments[order.Shipments[0].Id]).Address.HouseNum">
                </labeled-textbox>
              </td>
              <td>
                <labeled-textbox class="no-margin" [(model)]="$any(EditShipments[order.Shipments[0].Id]).Address.Entrance">
                </labeled-textbox>
              </td>
              <td>
                <labeled-textbox class="no-margin" [(model)]="$any(EditShipments[order.Shipments[0].Id]).Address.Floor">
                </labeled-textbox>
              </td>
              <td>
                <labeled-textbox class="no-margin" [(model)]="$any(EditShipments[order.Shipments[0].Id]).Address.Apartment">
                </labeled-textbox>
              </td>
            </ng-container>
            <td *ngIf="HasDropPointShipmentsType">
              <droppoint-shipment-selector [DropPointType]="DropPointType(EditShipments[order.Shipments[0].Id])" [DisplayMode]="2" [(DropPoint)]="$any(EditShipments[order.Shipments[0].Id]).DropPoint" (DropPointChange)="SaveShipment(EditShipments[order.Shipments[0].Id])" [Address]="$any(EditShipments[order.Shipments[0].Id]).Address">
              </droppoint-shipment-selector>

            </td>
            <td class="text-danger font-weight-bold ">{{EditShipments[order.Shipments[0].Id].ErrorMessage}}</td>
            <td class=""> <button class="btn btn-outline-primary btn-sm float-left" (click)="SaveShipment(EditShipments[order.Shipments[0].Id])" [disabled]="$any(SavingShipments).hasOwnProperty(order.Shipments[0].Id)"><i [ngClass]="$any(SavingShipments).hasOwnProperty(order.Shipments[0].Id) ? 'fa fa-spinner fa-pulse' : 'far fa-save'"></i>
                שמור</button> </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>

</div>