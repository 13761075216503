import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../services/authurization.service';


@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private authorizationService: AuthorizationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let roles = route.data.roles as Array<string>;

    if (this.authorizationService.DoseUserHasRole(roles)) return true;
    else {
      this.router.navigate(['/home'], {
        queryParams: {}
      });
      return false;
    }
  }
}