import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService, ConvertObjectToQuery } from './common.service';
import { IBaseQuery } from '../models';
declare var $: any;
import { IApiResult } from '../models/api.model';
import { IProduct } from '../models/products.models';
import { IPageResult, ILongRunningOperation } from '../models/common.models';


@Injectable()
export class OperationService {

  constructor(private _common: CommonService, private _http: HttpClient) { }
  private _apiUrl = "/api/Operations";
   
 

  public GetOperation(operationId: string): Observable<IApiResult<ILongRunningOperation>> {
    return this._http.get<IApiResult<ILongRunningOperation>>(this._apiUrl + '/Get?' + ConvertObjectToQuery({ OperationId: operationId }));
  }

  public SaveProduct(prod: IProduct): Observable<IApiResult<IProduct>> {
    return this._http.put<IApiResult<IProduct>>(this._apiUrl + '/Save',prod);
  }
}
