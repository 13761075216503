<!-- form -->
<form (ngSubmit)="submit($event)" [formGroup]="form">
    <input #input
    
           type="text"
           class="ng2-tag-input__text-input"
           autocomplete="off"
           tabindex="{{ disabled ? -1 : tabindex ? tabindex : 0 }}"
           minlength="1"
           formControlName="item"
           DefaultValueAccessor
           [ngClass]="inputClass"
           [attr.id]="inputId"
           [attr.placeholder]="placeholder"
           [attr.aria-label]="placeholder"
           [attr.tabindex]="tabindex"
           [attr.disabled]="disabled ? disabled : null"

           (focus)="onFocus.emit($event)"
           (blur)="onBlur.emit($event)"
           (keydown)="onKeyDown($event)"
           (keyup)="onKeyUp($event)"
    />
</form>
