import { createAction, props } from '@ngrx/store';
import { ILoginUserSession } from '../models/users.models';

export const UserSessionAppInit = createAction('[User Session] App Init');
export const UserSessionLoginStart = createAction('[User Session] Login Start', props<{ username: string; password: string }>());
export const UserSessionSSOLoginStart = createAction('[User Session] SSO Login Start');
export const UserSessionSSOLoginProcess = createAction('[User Session] SSO Login Process');
export const UserSessionLoginSuccess = createAction('[User Session] Login Success', props<{ session: ILoginUserSession }>());
export const UserSessionLoginError = createAction('[User Session] Login Error', props<{ message: string }>());

export const UserSessionRefreshStart = createAction('[User Session] Refresh Start');
export const UserSessionRefreshSuccess = createAction('[User Session] Refresh Success', props<{ session: ILoginUserSession }>());
export const UserSessionRefreshError = createAction('[User Session] Refresh Error', props<{ message: string }>());

export const UserSessionLogout = createAction('[User Session] Logout');
