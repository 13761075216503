import { Component, EventEmitter, Output, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { IShipmentTypes, UndefinedShipmentType } from '../../../models/shipments.models';

import { ShipmentService } from '../../../services/shipment.service';
import { CommonService } from '../../../services/common.service';
import { UserControlEditorMode } from '../../../models/user-controls';
import { AdminService } from '../../../services/admin.service';
import { filter, forkJoin, map, zip } from 'rxjs';
import { AbstractControl, FormControlStatus, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ConfigAvailableShipmentTypesFilter, ConfigStateFeature } from 'src/app/state/config.reducer';

declare var $: any;


@Component({
  selector: 'shipment-type-selector',
  templateUrl: './shipment.type.selector.usercontrol.component.html',
  styleUrls: ['./shipment.type.selector.usercontrol.component.css']
})
export class ShipmentTypeSelectorUserControlComponent implements OnInit, OnChanges {


  constructor(private _orderService: OrderService,
    private _common: CommonService,
    private store: Store,
    private _shipmentService: ShipmentService,
    private _adminService: AdminService) { }

  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() model!: string;

  @Input() public tabindex: number = 0;
  @Input() Mode!: UserControlEditorMode;
  @Input() ShowSelfDistributers: boolean = false;
  @Input() AllowUndefinedShipment: boolean = false;

  @Input() public bindFormControl!: AbstractControl;
  @Input() public validationMessages !: { [key: string]: string };

  public UndefinedShipmentValue = UndefinedShipmentType;
  public FormControlStatus!: FormControlStatus;
  @Output() OnValidChange: EventEmitter<FormControlStatus> = new EventEmitter<FormControlStatus>();

  public ActiveShipmentTypes!: IShipmentTypes;
  private _allShipmentTypes!: IShipmentTypes;

  @Input() public ExtraValues!: MethodSelectableItem[];

  public AvailableShipmentTypes!: MethodSelectableItem[];
  ngOnInit(): void {

    zip(
      this.store.select(ConfigStateFeature.selectShipmentTypes).pipe(filter(s => !!s)),
      this.store.select(ConfigStateFeature.availableShipmentTypes).pipe(filter(s => !!s),map(types => ConfigAvailableShipmentTypesFilter(types, this.ShowSelfDistributers, this.AllowUndefinedShipment)))
    ).subscribe(([allShipmentTypes, activeShipmentTypes]) => {
      //console.log(activeShipmentTypes);
      this._allShipmentTypes = allShipmentTypes;
      this.ActiveShipmentTypes = activeShipmentTypes;
      this.AvailableShipmentTypes = this.ExtraValues || [];
    });

    if (!this.model) this.model = "";

    if (!this.bindFormControl) this.CreateDefaultValidator();

    this.bindFormControl.valueChanges.subscribe(value => { this.modelChange.emit(value); });
    this.bindFormControl.statusChanges.subscribe(status => { this.OnValidChange.emit(status); })

  }


  ngOnChanges(changes: SimpleChanges): void {
    //this.modelChange.emit(this.model);
    if (changes.model?.currentValue != changes.model?.previousValue) {
      this.bindFormControl?.setValue(this.model);
    }
  }

  public CreateDefaultValidator() {
    this.bindFormControl = new UntypedFormControl(this.model,
      (control: AbstractControl) => {
        if (this.AllowUndefinedShipment && control.value == UndefinedShipmentType) return null;
        if (!this._shipmentService.IsValidShipmentItemType(control.value)) return { required: true };
        else return null;
      });

    if (!this.validationMessages) this.validationMessages = { "required": "יש לבחור שיטת משלוח" };
  }


  public GetShipmentTypeDescription(type: string) {
    if (this.ActiveShipmentTypes && this.ActiveShipmentTypes[type]) return this.ActiveShipmentTypes[type].Description;
    else if (this._allShipmentTypes && this._allShipmentTypes[type]) return this._allShipmentTypes[type].Description + " (לא פעיל)";
    else return "";
  }

}

export interface MethodSelectableItem {
  id: string;
  name: string;
}

