<div id="accordion" class="se-nav-menu">
  <div class="card-header" id="headingOne">
    <h5 class="mb-0">
      <button routerLink="/main" class="btn btn-link  btn-block" aria-expanded="true" tabindex="-1">
        דף פתיחה
      </button>
    </h5>
  </div>
  <ngb-accordion [closeOthers]="true" activeIds="static-1" #accordion>
    <ngb-panel id="static-1">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="card-header">
          <h5 class="m-0">
            <button class="btn btn-link btn-block collapsed" ngbPanelToggle> הזמנות </button>
          </h5>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="list-group list-group-flush card-header">
          <!--    <a routerLink="/order/new" class="list-group-item list-group-item-action  border-top" [ngClass]="{'active' : ActivePage === 'NewOrder' }" tabindex="-1">הזמנה חדשה</a>-->
          <a routerLink="/orders" [ngClass]="{'active' : ActivePage === 'Orders' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">כל הזמנות</a>
          <!--     <a routerLink="/orders" [queryParams]="{'OrderState': [1024] }" [ngClass]="{'active' : ActivePage === 'OrdersDratf' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">רשימת הזמנות בהקמה</a> -->
          <a routerLink="/orders" [queryParams]="{'ShipmentStatus': [1000] , 'OrderState': [1] , 'NotShipmentState' : [4]}" [ngClass]="{'active' :ActivePage === 'OrdersNewActive' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">מוכנות להדפסת משלוח</a>
          <a routerLink="/orders" [queryParams]="{'ERPExportStatus': [1] , 'OrderState': [1] , 'NotShipmentState' : [4] }" *ngIf="HasRole(['Admin', 'OrdersOperator'])" [ngClass]="{'active' :ActivePage === 'OrdersActiveReadyForErp' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">מוכנות להעברה ל-ERP</a>
          <a routerLink="/orders" [queryParams]="{'CollectStatus': [1] , 'OrderState': [1] }" *ngIf="HasRole(['Admin', 'OrdersOperator'])" [ngClass]="{'active' :ActivePage === 'OrdersActiveReadyForCollect' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">מוכנות לליקוט</a>
          <a routerLink="/orders" [queryParams]="{'ShipmentStatus': [2000,3000,3500,4000,9000] , 'OrderState': [1], 'OriginStore' :['Groupon', 'SuperPharm' , 'Behazdaa'], 'HasShipmentTrackingNumber' : true }" *ngIf="HasRole(['Admin', 'OrdersOperator'])" [ngClass]="{'active' :ActivePage === 'OrdersActiveReadyReport' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">מוכנות לדיווח</a>
          <a routerLink="/orders" [queryParams]="{'FailedValidationsType': [300103,290103] , 'Mode' : '{&quot;SplitProducts&quot;:true,&quot;AllowPriceListOverride&quot;:true}' , 'Fields' : '{&quot;Source.OperationTime&quot;:true,&quot;order.Id&quot;:true,&quot;Source.OriginStore&quot;:true,&quot;Items.Product.SKU&quot;:true,&quot;Items.Product.Name&quot;:true,&quot;Items.Price&quot;:true,&quot;Items.Product.StorePriceList.RecommendedPrice&quot;:true}'  }" [ngClass]="{'active' :ActivePage === 'OrdersProductPriceExceed' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">דוח חריגות מחירונים </a>
          <a routerLink="/orders" [queryParams]="{'ShipmentState': [4] }" [ngClass]="{'active' :ActivePage === 'OrdersWithShipmentErrors' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">
            <span>
              הזמנות עם כתובת שגויה
            </span>
            <span *ngIf="InvalidShipmentOrderCount$ |async " class="badge badge-pill badge-warning">{{InvalidShipmentOrderCount$ |async }}</span>
          </a>

          <a routerLink="/orders" [queryParams]="{'OrderState': [16] }" *ngIf="StockModuleEnable$ async" [ngClass]="{'active' :ActivePage === 'OrdersOutOfStock' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">
            <span>
              חסר במלאי
            </span>
            <span *ngIf="OutOfStockOrderCount$ |async " class="badge badge-pill badge-warning">{{OutOfStockOrderCount$ |async }}</span>
          </a>

          <a routerLink="/orders-shipment-merger" *ngIf="HasRole(['Admin', 'OrdersManager'])" class="list-group-item list-group-item-action  border-top" [ngClass]="{'active' : ActivePage === 'OrderShipmentMerger' }" tabindex="-1">איחוד משלוחים</a>
          <!--  
          <a routerLink="/orders" [queryParams]="{'CollectStatuses': [1] , 'Mode' : '{&quot;SplitProducts&quot;:true}' , 'Fields' : '{&quot;order.Id&quot;:true,&quot;Items.Product.SKU&quot;:true,&quot;Items.Product.Name&quot;:true,&quot;Items.Quantity&quot;:true}'  }" [ngClass]="{'active' :ActivePage === 'OrdersProductPriceExceed' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">דוח ליקוט </a>
        -->
          <a routerLink="/import-orders-upload" *ngIf="HasRole(['Admin', 'OrdersManager'])" class="list-group-item list-group-item-action  border-top" [ngClass]="{'active' : ActivePage === 'ImportOrders' }" tabindex="-1">יבוא הזמנות מקובץ</a>
          <a routerLink="/orders-wizard" [queryParams]=" { 'OrderState': [1024], 'OrderByFields': ['SupplierNumber'] }" *ngIf="HasRole(['Admin', 'OrdersManager'])" [ngClass]="{'active' :ActivePage === 'Orderswizard' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">
            <span>
              תהליך השלמת פרטים עבור הזמנות בהקמה
            </span>
            <span *ngIf="DraftOrderCount$ |async " class="badge badge-pill badge-warning">{{DraftOrderCount$ |async }}</span>
          </a>
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="static-2" *ngIf="HasRole(['Admin'])">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="card-header">
          <h5 class="m-0">
            <button class="btn btn-link btn-block collapsed" ngbPanelToggle> מוצרים </button>
          </h5>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="list-group list-group-flush">
          <a routerLink="/products" [ngClass]="{'active' : ActivePage === 'Products' }" class="list-group-item list-group-item-action  border-top" tabindex="-1">רשימת מוצרים</a>
          <a routerLink="/import-products-upload" [ngClass]="{'active' : ActivePage === 'ProductsImports'  }" class="list-group-item list-group-item-action  border-top" tabindex="-1">יבוא מוצרים מקובץ</a>
          <a routerLink="/product-catalogs" [ngClass]="{'active' : ActivePage === 'ProductCatalog'  }" class="list-group-item list-group-item-action  border-top" tabindex="-1">קטלוג</a>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="static-stock" *ngIf="(StockModuleEnable$ | async) && HasRole(['Admin'])">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="card-header">
          <h5 class="m-0">
            <button class="btn btn-link btn-block collapsed" ngbPanelToggle> מלאי </button>
          </h5>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="list-group list-group-flush">

          <a routerLink="/import-stock-upload" [ngClass]="{'active' : ActivePage === 'StocksImports'  }" class="list-group-item list-group-item-action  border-top" tabindex="-1">יבוא מלאי מקובץ</a>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="static-3" *ngIf="HasRole(['Admin'])">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="card-header">
          <h5 class="m-0">
            <button class="btn btn-link btn-block collapsed" ngbPanelToggle> הגדרות </button>
          </h5>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>

        <div class="list-group list-group-flush">
          <a routerLink="/admin/store" class="list-group-item list-group-item-action  border-top" [ngClass]="{'active' : ActivePage === 'AdminStore'  }" tabindex="-1">ערוצי מכירה</a>
          <a routerLink="/admin/shipment" class="list-group-item list-group-item-action  border-top" [ngClass]="{'active' : ActivePage === 'AdminShipment'  }" tabindex="-1">ערוצי הפצה</a>
          <a routerLink="/admin/custom-export" class="list-group-item list-group-item-action  border-top" [ngClass]="{'active' : ActivePage === 'AdminCustomExport'  }" tabindex="-1">פורמט ERP</a>
          <a routerLink="/admin/supplier" class="list-group-item list-group-item-action  border-top" [ngClass]="{'active' : ActivePage === 'AdminSupplier'  }" tabindex="-1">הגדרות כלליות</a>

        </div>

      </ng-template>
    </ngb-panel>
  </ngb-accordion>