<div class="card">
  <div class="card-body p-0">

    <div class="" [ngClass]="{'d-inline' : mode == 1}">
      <button class="btn btn-outline-primary" (click)="OpenFileDialog()" type="button" [hidden]="IsUploading" [ngClass]="{'rounded-0-right' : model.length}">
        <ng-container *ngIf="model.length">החלף</ng-container> <ng-container *ngIf="!model.length">בחר</ng-container> קובץ
        <i class="fas fa-file-upload"></i>
      </button>
      <button class="btn btn-outline-secondary" disabled type="button" [hidden]="!IsUploading" [ngClass]="{'rounded-0-right' : model.length}">
        טוען קובץ אנא המתן
        <i class="fa fa-spinner fa-pulse fa-fw "></i>
      </button>
    </div>
    <ng-container *ngFor="let file of model; index as i">
      <a *ngIf="file" class="mx-2" [download]="file.FileName" [href]="file.Path">{{file.FileName}}</a>
    </ng-container>

    <input type="file" style="display:none" [attr.multiple]="mode > 1 ? 'multiple' : null" (change)="UploadFilesInput( $event.target.files);" [attr.accept]="accept" placeholder="Upload file" #FileInput>

  </div> 
</div>