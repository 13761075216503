import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { IIdentity, DefaultShipment } from '../../../models'
declare var $: any;
import { OrderService } from '../../../services/order.service';
import { ShipmentService } from '../../../services/shipment.service';
import { CommonService } from '../../../services/common.service';
import { IOrderItem, IOrder, IProductOrderItem } from '../../../models/orders.models';
import { IShipment } from '../../../models/shipments.models';
import { DBOperationResult } from '../../../models/common.models';
import { EnumDescriptionPipe } from '../../../pipes/enumdescription.pipe';

import { ISupplierConfiguration } from '../../../models/suppliers';
import { AdminService } from '../../../services/admin.service';
import { IStoreDetails, StoreType } from '../../../models/stores';
import { StoreService } from '../../../services/store.service';
import { forkJoin, zip } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ConfigStateFeature } from 'src/app/state/config.reducer';



@Component({
  selector: 'app',
  templateUrl: './store.configuration.list.page.component.html',
  styleUrls: ['./store.configuration.list.page.component.css']
})
export class StoreConfigurationListPageComponent implements OnInit {
  private Configuration!: ISupplierConfiguration | null;

  constructor(
    private _adminService: AdminService,
    private _storeService: StoreService,
    private store: Store,
    private _router: Router) { }


  public ActiveStores!: IStoreDetails[];
  public InActiveStores!: IStoreDetails[];
  ngOnInit() {
    zip(
      this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)),
      this._storeService.GetStoreList()
    ).subscribe(([conf, stores]) => {
      this.Configuration = conf;
      this.BuildUI(stores);
    });
    /*
        this._adminService.CurrentConfiguration
          .pipe(
            switchMap(conf => {
              this.Configuration = conf;
              return this._storeService.GetStoreList();
            })
          ).subscribe(stores => this.BuildUI(stores));
    */
    //  this._adminService.RefreshCurrentConfiguration().subscribe();
  }

  public BuildUI(stores: IStoreDetails[]) {
    var storesFilter = stores;
    this.ActiveStores = storesFilter.filter(s => this.Configuration && this.Configuration.Stores && this.Configuration.Stores[s.IdName] && this.Configuration.Stores[s.IdName].Enabled);
    this.InActiveStores = storesFilter.filter(s => !(this.Configuration && this.Configuration.Stores && this.Configuration.Stores[s.IdName] && this.Configuration.Stores[s.IdName].Enabled));


  }

  public GotoStore(store: IStoreDetails) {
    this._router.navigate(["/admin/store", store.IdName]);
  }
}
