<div *ngIf="CurrntStep == 1" class="m-2">
  <p >
    <ngb-progressbar type="info" [striped]="true" [value]="Operation?.PresentComplete" height="50px" [striped]="true" [animated]="true">
      <h2 class="position-absolute px-2">{{Operation?.Message}}</h2>
    </ngb-progressbar>
  </p>
</div>


<div *ngIf="CurrntStep == 2">
  <h2>טעינה קבצים הושלמה</h2>

  <div class="mt-3 alert alert-success" role="alert" *ngIf="LoadedFiles">
    <p>
      <i class="far fa-file mr-2"></i>{{LoadedFiles}} קבצים מתוך {{FailedFiles.length + LoadedFiles}} נטענו בהצלחה!
    </p>
    <p><i class="fas fa-box mr-2"></i>נטענו {{FailedProducts +  LoadedProducts}} מוצרים.</p>
  </div>

  
  <div class="mt-3 alert alert-danger mb-4" role="alert">
    הקבצים הבאים לא נטענו בהצלחה :
    <ul class="list-group  ">
      <li class="list-group-item  list-group-item-danger" *ngFor="let file of FailedFiles; index as i"><i class="far fa-file mr-2"></i> {{file.Message}}</li>
    </ul>
    
  </div>
  <button class="float-right btn btn-secondary" (click)="FailedFilesContinue()"> המשך</button>
  <button class="float-left btn btn-danger" (click)="FailedFilesBack()"> חזור</button>
</div>

<div *ngIf="CurrntStep == 3">
  <h2>טעינה קבצים הושלמה</h2>
  <div class="mt-3 alert" [ngClass]="{'alert-success': OverallStatus ==  2, 'alert-warning': OverallStatus ==  3 , 'alert-danger': OverallStatus ==  4}" role="alert">
    <p>
      <i class="far fa-file mr-2"></i>{{FailedFiles.length + LoadedFiles}} קבצים מתוך {{LoadedFiles}} נטענו בהצלחה!
    </p>
    <p><i class="fas fa-box mr-2"></i>נטענו {{FailedProducts +  LoadedProducts}} מוצרים, מתוכן {{FailedProducts }} דורשות השלמת פרטים.</p>
  </div>

  <button class="float-right btn btn-success" (click)="Finish()"> חזור לדף הבית</button>

</div>

<div *ngIf="CurrntStep == 4">
  <h2>טעינה קבצים הושלמה</h2>
  <div class="mt-3 alert alert-success" role="alert">
    <p>
      <i class="far fa-file mr-2"></i>{{LoadedFiles}} קבצים מתוך {{FailedFiles.length + LoadedFiles}} נטענו בהצלחה!
    </p>
    <p><i class="fas fa-box mr-2"></i>נטענו {{FailedProducts +  LoadedProducts}} מוצרים.</p>
  </div>

  <button class="float-right btn btn-success" (click)="Finish()"> חזור לדף הבית</button>

</div>