import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'main-layout',
  templateUrl: './main.layout.component.html',
  styleUrls: ['./main.layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  public IsDlg: boolean;
  constructor(
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this._route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.IsDlg = (!!params.isdlg as any);
      });
  }



}
