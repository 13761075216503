import { Component, EventEmitter, Output, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { OrderService, IOrderSearchRequest } from '../../../services/order.service';
import {  AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { LOVsService } from '../../../services/LOVs.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'city-autocomplate-input',
  templateUrl: './city.autocomplate.input.usercontrol.component.html'
})
export class CityAutoComplateInpotUserControlComponent implements OnInit {


  constructor(private _orderService: OrderService,
    private _LOVsService: LOVsService) { }


  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() model!: string;

  @Input() public bindFormControl!: AbstractControl ;
  @Input() public validationMessages !: { [key: string]: string };
  
  @Input() validateOnInit!: boolean;
  @Input() title!: string;
  ngOnInit(): void {

    this.bindControls();

  }
  private bindControls() {

  }
  public RaiseChange() {
    this.modelChange.emit(this.model);
 
  }
  public SearchCity = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((text => {
        if (text.length >= 2) return this._LOVsService.SearchCities(text);
        else return of(null);

      })),

      map(searchResults => {
        if (!searchResults) return [];
        var result = searchResults.Result.Items;
        return result;
      }
      ));


}

