<div ngbDropdown class="d-inline-block">
    <button [class]="'dropdown-toggle ' + dropButClass" id="dropdownBasic_date_picker_options" ngbDropdownToggle>
        <ng-container>{{title}}</ng-container>
        <ng-container *ngIf="model"> : {{model.Title}}</ng-container>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic_date_picker_options">
        <ng-container *ngIf="model" >
            <button ngbDropdownItem (click)="Select(null)">נקה סינון</button>
            <div class="dropdown-divider"></div>
        </ng-container>
       
        <button ngbDropdownItem *ngFor="let option of Options" (click)="Select(option)">{{option.Title}}</button>
        <button ngbDropdownItem (click)="CustomDatePicker.OpenCustomDate()">בחר טווח ידני</button>
    </div>
</div>


<ng-template #contentCustomRange let-c="close" let-d="dismiss">

    <div class="modal-header">
        <div>
            <h4 class="modal-title">טווח תאריכים עבור {{title}}</h4>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="c('cross')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <ngb-datepicker *ngIf="CustomDatePicker" #dp (dateSelect)="CustomDatePicker.onCustomDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" class="ltr">
        </ngb-datepicker>


    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="c('save')" [disabled]="!CustomDatePicker.fromDate || !CustomDatePicker.toDate">שמור</button>
        <button type="button" class="btn btn-outline-dark" (click)="c('cancel')">בטל</button>
    </div>


  
</ng-template>
<ng-template #t let-date let-focused="focused">
    <span class="custom-day"
        [class.focused]="focused"
        [class.range]="CustomDatePicker.isRange(date)"
        [class.faded]="CustomDatePicker.isHovered(date) || CustomDatePicker.isInside(date)"
        (mouseenter)="CustomDatePicker.hoveredDate = date"
        (mouseleave)="CustomDatePicker.hoveredDate = null">
        {{ date.day }}
    </span>
</ng-template>