<div class="container">
  <div class="row">
    <div class="col" *ngIf="IsDisplayMode(1)">
      <button [disabled]="NearestPointButtonMessage" class="btn btn-outline-primary btn-block" [ngClass]="{'btn-outline-danger' : (FormControlStatus) && (FormControlStatus.State == 2) , 'btn-outline-success' : (FormControlStatus) && (FormControlStatus.State == 3) }" (click)="SelectNearestPoint()">
        <ng-container *ngIf="DropPoint && IsDisplayMode(4)">בחר נק' לפי כתובת</ng-container>
        <ng-container *ngIf="!DropPoint && IsDisplayMode(4)">שנה נק' לפי כתובת</ng-container><i class="ml-2" [ngClass]="{'fas fa-search-location': !IsInFindByAddressProcessingMode , 'fa fa-spinner fa-pulse' : IsInFindByAddressProcessingMode}"></i>
      </button>
      <div style="display:block" class="invalid-feedback text-left" *ngIf="NearestPointButtonMessage">{{NearestPointButtonMessage}}</div>
    </div>
    <div class="col" *ngIf="IsDisplayMode(2)">
      <button class="btn btn-outline-primary btn-block" [ngClass]="{'btn-outline-danger' : (FormControlStatus) && (FormControlStatus.State == 2) , 'btn-outline-success' : (FormControlStatus) && (FormControlStatus.State == 3) }" *ngIf="AvailableDropPoints" (click)="open(droppointMapSelector,{ size: 'lg' },null,null)">
        <ng-container *ngIf="DropPoint && IsDisplayMode(4)">בחר נק' ממפה</ng-container>
        <ng-container *ngIf="!DropPoint && IsDisplayMode(4)">שנה נק' ממפה</ng-container><i class="fas fa-map-marked-alt mx-2 fa-flip-horizontal"></i>
      </button>
    </div>
  </div>
</div>



<ng-template #droppointMapSelector let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">בחירת נקודת איסוף</h4>


    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="AvailableDropPoints">
    <droppoint-map [AvailableDropPoints] = "AvailableDropPoints" [DropPoint]="DropPoint" (DropPointChange)="Select($event)" >
    </droppoint-map>
  </div>
</ng-template>



