<div *ngIf="Order" class="container-fluid">
  <h2 class="row"><i class="fas fa-wrench mr-2"></i>נותרו {{ItemsCountLeft}} הזמנות לטיפול</h2>
  <i *ngIf="IsOrderLoading" class="fa fa-spinner fa-pulse fa-5x fa-fw row w-100 mt-5 text-secondary"></i>
  <ng-container *ngIf="!IsOrderLoading">

    <div class="border-top border-dark border-bottom border-3 mb-4" style="border-width: 3px !important;">
      <div class="row">
        <div class="col-6">
          <h4 class="font-weight-bold" *ngIf="!ValidationItemGroups[5000].State"> הזמנה : {{Order.SupplierNumber}}</h4>
          <div class="mt-3 d-flex align-items-start" *ngIf="ValidationItemGroups[5000].State">
            <h4>
              <order-supplier-number-textbox Title="הזמנה" [(Order)]="Order" (OnValidStateChange)="UpdateValidStateChange(5000,$event)">

              </order-supplier-number-textbox>
            </h4>
            <h5 class="border pt-1 pb-2 ml-4 px-2" *ngIf="ValidationItemGroups[5000].State != 'VALID'" [ngClass]="HTMLHelper.GetValidationCardHeaderClass(5000)">
              <span class="p-1" *ngFor="let validationItem of ValidationItemGroups[5000].OrderValidation; index as i">
                {{validationItem.Message}} <span *ngIf="validationItem.SubMessage">({{validationItem.SubMessage}})</span>
              </span>
            </h5>
          </div>
        </div>
        <div class="col-6">
          <ng-container *ngIf="ValidationItemGroups[3001]">
            <div *ngFor="let validationItem of ValidationItemGroups[3001].OrderValidation">
              <i class="fas fa-exclamation-triangle text-warning ng-star-inserted"></i><span class="">{{validationItem.Message}}</span> <span *ngIf="validationItem.SubMessage" class="font-weight-light"> - {{validationItem.SubMessage}}</span>
            </div>
          </ng-container>


        </div>
      </div>
      <div class="row">
        <h5 class="col-12">
          נוצרה ב {{Order.Audit.CreatedAt | date:"dd/MM/yyyy"}}<span *ngIf="Order.Source.Type != 1"> מ{{Order.Source.Type | enumDescription : "OrderSource"}} </span><span class="d-inline-block">({{FileSource?.FileName}})</span>
        </h5>
      </div>
    </div>


    <div class="card row" [ngClass]="HTMLHelper.GetValidationCardHeaderClass(5002)">
      <div class="card-header p-1">
        <span class="p-1" *ngFor="let validationItem of ValidationItemGroups[5002].OrderValidation; index as i">
          {{validationItem.Message}} <span *ngIf="validationItem.SubMessage">({{validationItem.SubMessage}})</span>
        </span>
      </div>
      <div class="card-body bg-white text-dark p-0">
        <div>
          <master-alias-product-binder class="mt-1" *ngIf="CurrentOrderProdutRef ; else OrderProductListShow" #MasterAliasProductBinder [Products]="CurrentOrderProdutRef" [Source]="Order.Source.OriginStore" [OrderId]="Order.Id" (OnRelationshipsChange)="OnRelationshipsChange($event)" (OnValidChange)="ValidationItemGroups[5002].State = $event;" [tabindex]="200">
          </master-alias-product-binder>
        </div>
        <ng-template #OrderProductListShow>


          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">שם</th>
                <th scope="col" class="text-center">מאפיינים</th>
                <th scope="col" class="text-center">מחיר יח'</th>
                <th scope="col" class="text-center">יח' בהזמנה</th>

                <th scope="col" class="text-center">סה"כ</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let item of ProductItems;">
                <td>

                  <div>
                    <b>{{item.Product.Name}}</b>
                  </div>
                  <div *ngIf="item.Product.SKU">
                    מקט: {{item.Product.SKU}}
                  </div>
                  <div *ngIf="item.Product.Model">
                    דגם : {{item.Product.Model}}
                  </div>
                </td>
                <td class="align-middle text-center">
                  <div *ngIf="item.Product.Attributes">{{item.Product.Attributes.join(', ')}}</div>
                </td>
                <td class="align-middle text-center">{{( item.ItemPrice + item.ItemVATPrice) | number:'1.2-2'}}</td>

                <td class="align-middle text-center">{{item.Quantity}}</td>


                <td class="align-middle text-center">{{item.Quantity * ( item.ItemPrice + item.ItemVATPrice) | number:'1.2-2' }}</td>
              </tr>
            </tbody>
          </table>



        </ng-template>
      </div>
    </div>
    <div class="row border-top border-dark border-bottom border-3 my-2 py-2" style="border-width: 3px !important;">
      <div class="col-6 d-flex align-items-stretch">
        <div>הערות בהזמנה:</div>
        <div>
          <h4 class="multi-line px-1">{{HTMLHelper.GetOrderCombineRemarks(Order)}}</h4>
        </div>
      </div>

      <div class="col-5 ml-auto pr-0 d-flex align-items-stretch">
        <div class="mr-2"> הערות לביצוע:</div>
        <div class="flex-grow-1">
          <textarea rows="4" tabindex="500" [(ngModel)]="Order.OperationalRemarks" class="w-100"></textarea>
        </div>

      </div>

    </div>

    <div class="row">
      <div class="col-6 pl-0">
        <div class="card" [ngClass]="HTMLHelper.GetValidationCardHeaderClass(5001)">
          <div class="card-header p-1">

            <span class="p-1" *ngFor="let validationItem of ValidationItemGroups[5001].OrderValidation; index as i">
              {{validationItem.Message}} <span *ngIf="validationItem.SubMessage">({{validationItem.SubMessage}})</span>
            </span>


            <i class="btn btn-outline-secondary fas fa-pencil-alt float-left" [ngClass]="{'btn-outline-light' : ValidationItemGroups[5001].State , 'btn-outline-dark' : !ValidationItemGroups[5001].State  }" (click)="ShipmentEditorMode = (ShipmentEditorMode == 1) ? 2 : 1"></i>
          </div>
          <div class="card-body bg-white text-dark">
            <shipment-editor [Mode]="ValidationItemGroups[5001].State == 2 ? 2 : ShipmentEditorMode" [(Shipment)]="Order.Shipments[0]" (OnValidChange)="UpdateValidStateChange(5001, $event); " #ShipmentEditor>

            </shipment-editor>
          </div>
        </div>


      </div>
      <div class="col-6 ml-auto">
        <div class="row mb-2">
          <div class="card w-100" [ngClass]="HTMLHelper.GetValidationCardHeaderClass(5001.5)">
            <div class="card-header p-1">
              <span class="p-1" [ngClass]="{'strikethrough' : ValidationItemGroups[5001.5].State == 3}" *ngFor="let validationItem of ValidationItemGroups[5001.5].OrderValidation; index as i">
                {{validationItem.Message}} <span *ngIf="validationItem.SubMessage">({{validationItem.SubMessage}})</span>
              </span>
              <i class="btn  btn-outline-secondary  fas fa-pencil-alt float-left" [ngClass]="{'btn-outline-light' : ValidationItemGroups[5001.5].State , 'btn-outline-dark' : !ValidationItemGroups[5001.5].State }" (click)="ShipmentMethodEditorMode = (ShipmentMethodEditorMode == 1) ? 2 : 1"></i>
            </div>
            <div class="card-body bg-white text-dark">
              <div class="mb-1" *ngIf="UndefineShipmentTypeOrderValue">
                <div>הערך <ng-container *ngIf="ShipmentItems?.length > 1">הראשון</ng-container> שהתקבל בהזמנה : <b>{{UndefineShipmentTypeOrderValue}}</b></div>
                <div *ngIf="ShipmentItems?.length">
                  <span>עלות משלוח שהתקבלה בקובץ : <b>{{( ShipmentItems[0].ItemPrice + ShipmentItems[0].ItemVATPrice) | number:'1.2-2'}} ש"ח</b></span>

                  <span class="ml-4">
                    <checkbox [(model)]="AddUndefineDefaultOrderValueIncludePrice"></checkbox>
                    <label class="mx-2" (click)="AddUndefineDefaultOrderValueIncludePrice = (!AddUndefineDefaultOrderValueIncludePrice)">קבע שיטת משלוח גם לפי מחיר</label>
                  </span>

                </div>


              </div>


              <shipment-type-selector #ShipmentTypeSelector [model]="Order.Shipments[0].ItemType" (modelChange)="ChangeShipmentType($event,true);" (OnValidChange)="ValidationItemGroups[5001.5].State = $event;cd.detectChanges(); " [Mode]="ShipmentMethodEditorMode" [ShowSelfDistributers]="true">
              </shipment-type-selector>

              <div class="mt-1" *ngIf="UndefineShipmentTypeOrderValue">
                <checkbox [(model)]="AddUndefineDefaultOrderValue"></checkbox>
                <label class="mx-2" (click)="AddUndefineDefaultOrderValue = (!AddUndefineDefaultOrderValue)">החל בחירה זו על כול ההזמנות עם הערך הזה</label>
              </div>

              <div *ngIf="ShipmentItems.length > 1" class="text-secondary"><i class="fas fa-exclamation-triangle text-warning"></i> שם לב שיש יותר משורת משלוח אחת בהזמנה </div>

            </div>
          </div>

        </div>

        <div class="row">
          <div class="card w-100">
            <div class="card-header  p-1">
              <span class="p-1">פרטי קונה</span>
              <i class="btn btn-outline-secondary btn-outline-dark fas fa-pencil-alt float-left" (click)="ShopperEditorMode = (ShopperEditorMode == 1) ? 2 : 1"></i>
            </div>
            <div class="card-body bg-white text-dark">
              <identity-editor [(Identity)]="Order.Shopper" [Mode]="ShopperEditorMode">

              </identity-editor>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-stretch mt-2 p-0">
        <div class="">
          <button type="button" tabindex="3500" class="btn btn-outline-secondary" (click)="SkipOrder();"><i class="fas fa-forward"></i><span class="pl-2">דלג</span></button>
          <small class="text-secondary d-block">יעבור להזמנה הבאה בלי להשלים את הפרטים</small>
        </div>
        <div class="">
          <button type="button" tabindex="3000" class="btn btn-outline-danger" (click)="open(contentDeleteAllOrders,null,null,DeleteAllOrders);"><span class="pl-2">בטל את שארית ההזמנות</span><i class="fas fa-trash-alt ml-1"></i></button>
          <small class="text-secondary d-block">ימחק את כול ההזמנות שנותרו מהמערכת</small>
        </div>
        <div class="">
          <button type="button" tabindex="2000" class="btn btn-outline-danger " (click)="open(contentDeleteOrder,null,null,DeleteOrder)"><span>בטל הזמנה</span> <i class="far fa-trash-alt ml-1"></i></button>
          <small class="text-secondary d-block">ימחק רק את ההזמנה הנוכחית מהמערכת</small>
        </div>
        <div class="">
          <button type="button" tabindex="1000" class="btn btn-lg " [ngClass]="{'btn-outline-success' : IsOrderFixed(), 'btn-outline-dark' : !IsOrderFixed()}" [disabled]="!IsOrderFixed() || IsOrderSaving" (click)="SaveOrder();">
            <h3 class="d-inline-block mr-2">עדכן</h3>
            <i class="fa" [ngClass]="{'fa-spinner fa-pulse' : IsOrderSaving , 'fa-save' : !IsOrderSaving}"></i>
          </button>

        </div>

      </div>
    </div>
  </ng-container>
</div>














<div *ngIf="ItemsCountLeft == 0">
  <div class="alert alert-success" role="alert">
    תהליך תיקון ההזמנות הסתיים בהצלחה
  </div>

  <ng-container *ngIf="ReturnUrl; else multiOrderModeFixed">
    <button type="button" class="btn btn-outline-dark" (click)="NavigateToReturnUrl();">{{!!ReturnDescription ? ReturnDescription : 'חזור' }}</button>
  </ng-container>
  <ng-template #multiOrderModeFixed>
    <button type="button" class="btn btn-outline-dark" (click)="NavigateToOrdersList();">עבור לרשימת ההזמנות שטופלו</button>
  </ng-template>


</div>


<ng-template #contentDeleteOrder let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור מחיקה</h4>
    <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    האם אתה בטוח שאתה רוצה למחוק הזמנה {{Order?.SupplierNumber}} ?
  </div>
  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-danger float-right" (click)="c('save')">אשר <i class="far " [ngClass]="{'fa-spinner fa-pulse' : IsOrderDeleting , 'fa-trash-alt' : !IsOrderDeleting}"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>

<ng-template #contentDeleteAllOrders let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור מחיקה</h4>
    <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    האם אתה בטוח שאתה רוצה למחוק את כול שאר ההזמנות ?
    <h6>סה"כ {{ItemsCountLeft}} הזמנות</h6>
  </div>
  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-danger float-right" (click)="c('save')">אשר <i class="far " [ngClass]="{'fa-spinner fa-pulse' : IsOrderDeleting , 'fa-trash-alt' : !IsOrderDeleting}"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>