<ng-container *ngIf="Identity">
  <ng-container *ngIf="Mode==1">
    <div><span>{{Identity?.FullName}}</span> <span class="float-left ltr">{{Identity?.Phone}}</span></div>
    <div *ngIf="Identity?.Email">{{Identity?.Email}}</div>
  </ng-container>
  <ng-container *ngIf="Mode==2">
    <div class="container-fluid p-0 m-0">
      <div class="row row-compact">
        <span class="col-7"><labeled-textbox type="text" title="שם" [bindFormControl]="ValidationFrom.controls.FullName" ></labeled-textbox></span>
        <span class="col-5"><labeled-textbox type="tel" title="טלפון" [bindFormControl]="ValidationFrom.controls.Phone"  ></labeled-textbox></span>
      </div>
      <div class="row row-compact">
        <div class="col-12">
          <labeled-textbox type="email" title="אימייל" [bindFormControl]="ValidationFrom.controls.Email"  [validationMessages]="{email:'כתובת איימיל לא חוקית'}" ></labeled-textbox>
        </div>
      </div>

    </div>
  </ng-container>
</ng-container>

