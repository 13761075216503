import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../../services/authurization.service';
import { OrderService } from 'src/app/services/order.service';
import { ILoginUserSession } from 'src/app/models/users.models';
import { Store } from '@ngrx/store';
import { SessionStateFeature } from 'src/app/state/session.reducer';
import { UserSessionLogout } from 'src/app/state/session.actions';
import { Router } from '@angular/router';
import { statisticsStateFeature } from 'src/app/state/statistics.reducer';

@Component({
  selector: 'header-menu',
  templateUrl: './header.menu.usercontrol.component.html',
  styleUrls: ['./header.menu.usercontrol.component.css']
})
export class HeaderMenuUserControlComponent implements OnInit, OnDestroy {


  constructor(private store: Store, private _orderService: OrderService, private router: Router) { }

  public UserSession!: ILoginUserSession;
  public ERPErrorOrderCount: number = 0

  private listener$ = this.store.select(SessionStateFeature.selectSession).subscribe(session => {
    if (session) this.UserSession = session;
    else this.router.navigate(['/login']);
  })
  public logout() {
    this.store.dispatch(UserSessionLogout());


  }
  ngOnInit(): void {
    this.store.select(statisticsStateFeature.selectErpErrorOrderCount).subscribe(count => this.ERPErrorOrderCount = count);
  }

  ngOnDestroy(): void {
    this.listener$.unsubscribe();
  }
}
