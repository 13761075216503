import { Injectable } from '@angular/core';
import * as Common from '../models/common.models'
import { AuthorizationService } from './authurization.service'
import { ILoginUserSession } from '../models/users.models';
import { Store } from '@ngrx/store';
declare var $: any;

@Injectable()
export class CommonService {
  private session : ILoginUserSession
  constructor(private _authService: AuthorizationService,private store: Store) {

  }
/*
  CreateNewItemAuditTrace(): Common.IItemAuditTrace {

    var userID: string = "";
    var userName: string = "";
    if (this.session) {
      userID = this.session.UserID;
      userName = this.session.FullName;
    }
    return {
      CreatedAt: new Date(),
      CreatedById: userID,
      CreatedByName: userName,
      ModifiedAt: new Date(),
      ModifiedById: "",
      ModifiedByName: ""
    }
  }
*/
  CreateNewIIdentity(): Common.IIdentity {
    return {
      Email: "",
      FullName: "",
      Phone: ""
    };
  }


  CreateNewIDBObject(): Common.IDBObject {
    return {
      Id: null,
      Audit: null,
      SupplierId: null
    };
  }
  CreateNewIAddress(): Common.IAddress {
    return {
      City: "",
      Street: "",
      HouseNum: "",
      Floor: "",
      Entrance: "",
      Apartment: "",
      Zip: "",
      POBox: "",
      Company: "",
      Remarks: ""
    };
  }

}


export function ConvertObjectToQuery(obj: Object): string {
  // convert a[parm1]= val to a.parm1=val
  // onvert a[]=var1&a[]=var2 to a=var1&a=var2
  // see http://api.jquery.com/jquery.param/ this the traditional  = false to include nested objects.

  return $.param(JSON.parse(JSON.stringify(obj))) // bug fix for complex objects like Dates 
    .replace(new RegExp("\\[", 'g'), ".")
    .replace(new RegExp(encodeURIComponent("["), 'g'), ".")
    .replace(new RegExp("\\]", 'g'), "")
    .replace(new RegExp(encodeURIComponent("]"), 'g'), "")
    .replace(new RegExp("\\.=", 'g'), "=");
}

export function RoundNumber(number: number, decimalPoints?: number): number {

  const base = decimalPoints ? Math.pow(10, decimalPoints) : 1;
  return (Math.round((number + Number.EPSILON) * base) / base);
}

export function CompareArray<T>(arr1: Array<T>, arr2: Array<T>): boolean {
  if (!arr1 || !arr2) return false;
  let result;

  arr1.forEach((e1, i) => arr2.forEach(e2 => {
    if (e1 !== e2) {
      result = false
    } else {
      result = true
    }
  })
  )

  return result

}