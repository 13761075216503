import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ILongRunningOperation, ILongRunningOperationItem, LongRunningOperationItemType, LongRunningOperationItemStatus } from '../../../models/common.models';
import { OperationService } from '../../../services/operation.service';

import { OrderService, IOrderSearchRequest } from '../../../services/order.service';
import { OrderState } from '../../../models/orders.models';
@Component({
  templateUrl: './order-import.process.page.component.html',
  styleUrls: ['./order-import.process.page.component.css'],

})
export class OrderImportProcessPageComponent implements OnInit, OnDestroy {

  constructor(
    private _route: ActivatedRoute,
    private _opertionService: OperationService,
    private _orderService: OrderService,
    private _router: Router) { }


  public Operation!: ILongRunningOperation;
  public CurrntStep: OrderImportStep = OrderImportStep.ServerProcessing;
  private timer: number = 0;
  ngOnInit(): void {

    this._route.params.subscribe(params => {
      // need to make rxjs timer
      this.loadOperation(params['SourceOperationID']);
    });
  }

  private loadOperation(SourceOperationID: string) {

    this._opertionService.GetOperation(SourceOperationID)
      .subscribe(r => {
        if (r.Result) {
          this.Operation = r.Result;
          if (r.Result.PresentComplete == 100) {
            this.validateOperation();
          }
          else {
            this.timer = setTimeout(() => { this.loadOperation(SourceOperationID); }, 500) as unknown as number;
          }
        }
      });
  }

  public FailedFiles!: ILongRunningOperationItem[];
  public WarningFiles!: ILongRunningOperationItem[];
  public FailedOrders!: number;
  public LoadedFiles!: number;
  public LoadedOrders!: number;
  public OverallStatus!: LongRunningOperationItemStatus;
  public SkippedOrders!: { Message: string, OrderNumbers: string[] }[];
  private validateOperation() {
    if (this.Operation.Items) {
      this.FailedFiles = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status == LongRunningOperationItemStatus.Failure);
      this.WarningFiles = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status == LongRunningOperationItemStatus.Warning);

      this.FailedOrders = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.Order && i.Status > 0 && i.Status != LongRunningOperationItemStatus.Success)
        .reduce((agg, item) => agg + (item.ObjectsCount || 0), 0);

      this.LoadedFiles = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && (i.Status == LongRunningOperationItemStatus.Success || i.Status == LongRunningOperationItemStatus.Warning)).length;
      this.LoadedOrders = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.Order && i.Status == LongRunningOperationItemStatus.Success)
        .reduce((agg, item) => agg + (item.ObjectsCount || 0), 0);

      this.OverallStatus = Math.max.apply(null, this.Operation.Items.filter(i => i.Status > 0).map(i => i.Status as number)) as LongRunningOperationItemStatus;
      this.SkippedOrders = this.Operation.Items.filter(i => i.Status < 0).map(i => {
        return {
          Message: i.Message,
          OrderNumbers: i.ObjectsCodes ?? []
        }
      });
    }

    if ((this.FailedFiles.length + this.WarningFiles.length) > 0) this.CurrntStep = OrderImportStep.FileMessages;
    else if (this.FailedOrders > 0) this.CurrntStep = OrderImportStep.SummeryResult;
    else this.CurrntStep = OrderImportStep.Complate;
  }
  public FailedFilesContinue() {
    if (this.FailedOrders > 0) this.CurrntStep = OrderImportStep.SummeryResult;
    else this.CurrntStep = OrderImportStep.Complate;
  }

  public FailedFilesBack() {
    this._router.navigate(['/import-orders-upload']);
  }



  public ActivateValidOrders(callback: Function) {
    this._orderService.SetState({
      SourceOperationID: this.Operation.Id as string,
      NotOrderState: [OrderState.OutOfStock]
    }, OrderState.Active).subscribe(r => {
      if (callback) callback();

    });
  }
  public Finish() {
    this.ActivateValidOrders(() => {
      this.CurrntStep = OrderImportStep.Complate;
      var searchQuery: IOrderSearchRequest = { SourceOperationID: this.Operation.Id as string };
      this._router.navigate(['/orders'], { queryParams: searchQuery });
    });

  }

  public ShowWizard() {
    this.ActivateValidOrders(() => {
      this.CurrntStep = OrderImportStep.Complate;
      var searchQuery: IOrderSearchRequest = { SourceOperationID: this.Operation.Id as string, OrderByFields: ['SupplierNumber'] };
      this._router.navigate(['/orders-wizard'], { queryParams: searchQuery });
    });
  }

  ngOnDestroy() {
    if (this.timer) clearTimeout(this.timer);
    if (this.CurrntStep !== OrderImportStep.Complate) {
      if (this.Operation.Id) {
        this._orderService.DeleteOrders({ SourceOperationID: this.Operation.Id }, "Cancel Import")
          .subscribe();
      }

    }
  }
}

enum OrderImportStep {
  ServerProcessing = 1,
  FileMessages = 2,
  SummeryResult = 3,
  Complate = 4
}

