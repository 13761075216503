<div>

  <button type="button" class="btn btn-outline-primary btn-sm" (click)="CreateNewCatalog();">צור קטלוג חדש</button>

  <table class="table table-hover table-sm" *ngIf="catalogs$ | async">
    <thead>
      <tr>
        <!--        <th scope="col" [style.height.%]="HTMLHelper.TableColumnWidths[SelectedStoreView? 'StoreSelected' : 'Default'][0]">סמן</th>-->
        <th scope="col">קוד</th>
        <th scope="col">שם</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let catalog of catalogs$ | async">
        <td><a [routerLink]="'/product-catalog/'+ catalog.Id"> {{catalog.Code}}</a></td>
        <td>{{catalog.Name}}</td>
        <td>


          <button type="button" tabindex="1002" class="btn btn-outline-danger mx-4 float-left" (click)="SelectedProductCatalog = catalog;  open(contentDelete,null,null,DeleteSelectedProductCatalog)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>


<ng-template #contentDelete let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור מחיקה</h4>
    <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    האם אתה בטוח שאתה רוצה למחוק את הקטלוג {{SelectedProductCatalog.Name}} ?
  </div>
  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-danger float-right" (click)="c('save')">אשר <i class="far " [ngClass]="{'fa-spinner fa-pulse' : IsOrderDeleting , 'fa-trash-alt' : !IsOrderDeleting}"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>