<ng-container *ngIf="Shipment">
  <!-- view mode -->

  <div *ngIf="Mode==1" class="container-fluid p-0 m-0">
    <div class="row row-compact" style="height:36px; padding-right:7px; padding-top:3px;">
      <div class="col-12">
        {{Shipment.Recipient.FullName}}
      </div>
    </div>
    <div class="row row-compact " style="height:36px; padding-right:7px; padding-top:3px;">
      <div class="col-5 ltr">
        {{Shipment.Recipient.Phone}}
      </div>

      <div class="col-5 mlll-auto" *ngIf="Shipment.Recipient?.SecondaryPhone">
        מספר נוסף : <span dir="ltr">{{Shipment.Recipient.SecondaryPhone}}</span>
      </div>
    </div>
    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.Recipient?.Email">{{Shipment.Recipient?.Email}}</div>


    <ng-container *ngIf="Shipment.Address && (!Shipment.DropPoint)">
      <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.Address.Street">{{Shipment.Address.Street}}, {{Shipment.Address.HouseNum}}</div>
      <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.Address.Entrance || Shipment.Address.Floor || Shipment.Address.Apartment">
        <ng-container *ngIf="Shipment.Address.Entrance"> כניסה {{Shipment.Address.Entrance}}</ng-container>
        <ng-container *ngIf="Shipment.Address.Floor"> קומה {{Shipment.Address.Floor}}</ng-container>
        <ng-container *ngIf="Shipment.Address.Apartment"> דירה {{Shipment.Address.Apartment}}</ng-container>
      </div>
      <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.Address.POBox || Shipment.Address.Zip || Shipment.Address.Company">
        <ng-container *ngIf="Shipment.Address.POBox">תא דואר {{Shipment.Address.POBox}}</ng-container>
        <ng-container *ngIf="Shipment.Address.Company"> חברה {{Shipment.Address.Company}}</ng-container>
        <ng-container *ngIf="Shipment.Address.Zip"> מיקוד {{Shipment.Address.Zip}}</ng-container>
      </div>
      <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.Address.City">{{Shipment.Address.City}}</div>
      <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.Address.Remarks">הערות: {{Shipment.Address.Remarks}}</div>
    </ng-container>


  </div>


  <!-- edit mode -->
  <div *ngIf="Mode==2" class="container-fluid p-0 m-0">
    <div class="row row-compact">
      <div class="col-12">
        <labeled-textbox title="שם לקוח" class="compact" [(model)]="Shipment.Recipient.FullName" (modelChange)="RaiseChange()" [bindFormControl]="ValidationFrom.controls.RecipientFullName" [validationMessages]="{required : 'חובה להכניס שם לקוח'}"></labeled-textbox>
      </div>
    </div>
    <div class="row row-compact">
      <div class="col-5">
        <labeled-textbox type="tel" title="טלפון" class="compact" [(model)]="Shipment.Recipient.Phone" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>

      <div class="col-5 mlll-auto">
        <labeled-textbox type="tel" title=" טלפון נוסף" class="compact" [(model)]="Shipment.Recipient.SecondaryPhone" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
    </div>
    <div class="row row-compact">
      <div class="col-12">
        <labeled-textbox type="email" title="אימייל" class="compact" [(model)]="Shipment.Recipient.Email" (modelChange)="RaiseChange()" [bindFormControl]="ValidationFrom.controls.RecipientEmail" [validationMessages]="{email:'כתובת איימיל לא חוקית'}"></labeled-textbox>
      </div>
    </div>


  </div>

  <div *ngIf="Shipment.DropPoint">

    <ng-container *ngIf="Shipment.DropPoint.ItemType == 'BoxitDropPoint'">
      <h3 class="row row-compact">
        <span *ngIf="Shipment.DropPoint.LockerType == 1"> פרטי לוקר : </span> <span *ngIf="Shipment.DropPoint.LockerType == 3">פרטי חנות : </span> {{Shipment.DropPoint.Name}}
      </h3>
      <div>
        <ng-container *ngIf="Shipment.DropPoint.Address">
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Street">{{Shipment.DropPoint.Address.Street}}, {{Shipment.DropPoint.Address.HouseNum}}</div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Entrance || Shipment.DropPoint.Address.Floor || Shipment.DropPoint.Address.Apartment">
            <ng-container *ngIf="Shipment.DropPoint.Address.Entrance"> כניסה {{Shipment.DropPoint.Address.Entrance}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Floor"> קומה {{Shipment.DropPoint.Address.Floor}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Apartment"> דירה {{Shipment.DropPoint.Address.Apartment}}</ng-container>
          </div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.POBox || Shipment.DropPoint.Address.Zip || Shipment.DropPoint.Address.Company">
            <ng-container *ngIf="Shipment.DropPoint.Address.POBox">תא דואר {{Shipment.DropPoint.Address.POBox}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Company"> חברה {{Shipment.DropPoint.Address.Company}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Zip"> מיקוד {{Shipment.DropPoint.Address.Zip}}</ng-container>
          </div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.City">{{Shipment.DropPoint.Address.City}}</div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Remarks">הערות: {{Shipment.DropPoint.Address.Remarks}}</div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="Shipment.DropPoint.ItemType == 'ChitaDropPoint' || Shipment.DropPoint.ItemType == 'YDMDropPoint' || Shipment.DropPoint.ItemType == 'SosnaDropPoint' || Shipment.DropPoint.ItemType == 'EPostDropPoint' || Shipment.DropPoint.ItemType == 'OrionDropPoint'" >
      <h3 class="row row-compact">
        <span>פרטי חנות : </span> {{Shipment.DropPoint.Name}}
      </h3>
      <div>
        <ng-container *ngIf="Shipment.DropPoint.Address">
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Street">{{Shipment.DropPoint.Address.Street}}, {{Shipment.DropPoint.Address.HouseNum}}</div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Entrance || Shipment.DropPoint.Address.Floor || Shipment.DropPoint.Address.Apartment">
            <ng-container *ngIf="Shipment.DropPoint.Address.Entrance"> כניסה {{Shipment.DropPoint.Address.Entrance}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Floor"> קומה {{Shipment.DropPoint.Address.Floor}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Apartment"> דירה {{Shipment.DropPoint.Address.Apartment}}</ng-container>
          </div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.POBox || Shipment.DropPoint.Address.Zip || Shipment.DropPoint.Address.Company">
            <ng-container *ngIf="Shipment.DropPoint.Address.POBox">תא דואר {{Shipment.DropPoint.Address.POBox}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Company"> חברה {{Shipment.DropPoint.Address.Company}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Zip"> מיקוד {{Shipment.DropPoint.Address.Zip}}</ng-container>
          </div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.City">{{Shipment.DropPoint.Address.City}}</div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Remarks">הערות: {{Shipment.DropPoint.Address.Remarks}}</div>
        </ng-container>
      </div>

      <div class="m-0 my-3">
        <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Remarks">
          <ng-container *ngIf="Shipment.DropPoint.Remarks.indexOf('שעות') == -1">
            שעות פתיחה:
          </ng-container>
          {{Shipment.DropPoint.Remarks}}
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="Shipment.DropPoint.ItemType == 'UPSDropPoint'">
      <h3 class="row row-compact">
        <span *ngIf="Shipment.DropPoint.DropPointType == 1"> פרטי לוקר : </span> <span *ngIf="Shipment.DropPoint.DropPointType == 2">פרטי חנות : </span> {{Shipment.DropPoint.Name}}
      </h3>
      <div>
        <ng-container *ngIf="Shipment.DropPoint.Address">
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Street">{{Shipment.DropPoint.Address.Street}}, {{Shipment.DropPoint.Address.HouseNum}}</div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Entrance || Shipment.DropPoint.Address.Floor || Shipment.DropPoint.Address.Apartment">
            <ng-container *ngIf="Shipment.DropPoint.Address.Entrance"> כניסה {{Shipment.DropPoint.Address.Entrance}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Floor"> קומה {{Shipment.DropPoint.Address.Floor}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Apartment"> דירה {{Shipment.DropPoint.Address.Apartment}}</ng-container>
          </div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.POBox || Shipment.DropPoint.Address.Zip || Shipment.DropPoint.Address.Company">
            <ng-container *ngIf="Shipment.DropPoint.Address.POBox">תא דואר {{Shipment.DropPoint.Address.POBox}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Company"> חברה {{Shipment.DropPoint.Address.Company}}</ng-container>
            <ng-container *ngIf="Shipment.DropPoint.Address.Zip"> מיקוד {{Shipment.DropPoint.Address.Zip}}</ng-container>
          </div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.City">{{Shipment.DropPoint.Address.City}}</div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Address.Remarks">הערות: {{Shipment.DropPoint.Address.Remarks}}</div>
          <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Shipment.DropPoint.Comments">{{Shipment.DropPoint.Comments}}</div>
        </ng-container>
      </div>
    </ng-container>
    <div class="row row-compact" style="height:36px;  padding-right:7px; padding-top:3px;" *ngIf="Mode==1 && Shipment.TrackingNumber">
      מספר מעקב: {{Shipment.TrackingNumber}}
    </div>
    <div class="row row-compact" *ngIf="Mode==2">
      <div class="col-12">
        <labeled-textbox title="מספר מעקב" class="compact" [(model)]="Shipment.TrackingNumber" (modelChange)="RaiseChange()"></labeled-textbox>
      </div>
    </div>
  </div>

  <div *ngIf="Mode==2">
    <div class="my-2 mx-3">
      <button class="btn btn-outline-primary btn-block btn-outline-primary" (click)="refreshAddress(); open(AddressEditor,{ size: 'lg' },null,null)">
        <span>הצג/ערוך כתובת נמען</span><i class="ml-2 far fa-address-card"></i>
      </button>
    </div>
    <droppoint-shipment-selector *ngIf="Mode==2" [DropPointType]="DropPointType()" [(DropPoint)]="Shipment.DropPoint" [Address]="Shipment.Address" (DropPointChange)="RaiseChange()" [validationControl]="ValidationFrom.controls?.DropPoint">
    </droppoint-shipment-selector>
  </div>
  <div>

  </div>


</ng-container>


<ng-template #AddressEditor let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">עריכת כתובת</h4>


    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <address-editor [(Address)]="Shipment.Address" [Mode]="2" validateOnInit="true"></address-editor>


  </div>

</ng-template>