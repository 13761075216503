<h2>{{StatusDescription}}</h2>
<ng-container >
  <div *ngFor="let workitem of WorkItems; index as i">

    <div *ngIf="workitem.Step == 1" class="m-2">
      <h2>{{workitem.Title}}</h2>
      <p>
        <ngb-progressbar type="info" [striped]="true" [value]="workitem?.Operation?.PresentComplete" height="50px" [striped]="true" [animated]="true">
          <h2 class="position-absolute px-2">{{workitem?.Operation?.Message}}</h2>
        </ngb-progressbar>
      </p>
    </div>

    <div *ngIf="workitem.Step == 4">

      <div class="mt-3 alert alert-success" role="alert">
        <h3>{{workitem.Title}} מוכן</h3>
        <p *ngIf="workitem.File && ClientPostProcessed">
          <ng-container *ngIf="IsDownloadable(workitem.File.Type) ; then fileDownloadTemplate; else printTemplate"></ng-container>
          <ng-template #fileDownloadTemplate>
            <a [href]="workitem.File.Path" [download]="workitem.File.FileName">להורדת הקובץ לחץ כאן</a>
          </ng-template>
          <ng-template #printTemplate>
            <a [href]="workitem.File.Path" target="_blank">להדפסה לחץ כאן</a>
          </ng-template>
          
        </p>
      </div>

    </div>

    <div *ngIf="workitem.Step == 8 || workitem.Step == 16">

      <div class="mt-3 alert alert-danger" role="alert">
        <h3>{{workitem.Title}}</h3>
        <p *ngIf="workitem.Operation">
          {{workitem.Operation.Items[0].Message}}
        </p>
      </div>
    </div>

  </div>

  <button class="float-right btn btn-success ml-2" (click)="Finish()">{{!!ReturnDescription ? ReturnDescription : 'חזור'}}</button>
</ng-container>

