import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { IApiResult } from "../models/api.model";
import { ISupplierConfiguration } from "../models/suppliers";


declare var $: any;

@Injectable()
export class UserService {


    private _apiUrl = "/api/Users";
    private _currentPreferences: { [Key: string]: any } | null = null;

    private _currentPreferencesBS = new ReplaySubject<{ [Key: string]: any } | null>(1);


    constructor(private _http: HttpClient) {

        this._currentPreferencesBS.subscribe(preferences => {
            this._currentPreferences = preferences;
        })
        this.RefreshCurrentConfiguration().subscribe();
    }


    public GetUserPreference(key: string): Observable<any> {
        return this._currentPreferencesBS.pipe(
         //   first(),
            map(preferences => preferences ? preferences[key] : null));
    }

    public SetUserPreference(key: string, value: any): Observable<any> {
        var preferences = $.extend({}, this._currentPreferences);
        preferences[key] = value;

        return this.SaveUserPreferences(preferences)
            .pipe(map(preferences => preferences ? preferences[key] : null));
    }


    private GetCurrentPreferences(): Observable<IApiResult<{ [Key: string]: any } | null>> {
        return this._http.get<IApiResult<{ [Key: string]: any } | null>>(this._apiUrl + '/GetPreferences');
    }

    private SaveUserPreferences(preferences: { [Key: string]: any }): Observable<IApiResult<{ [Key: string]: any } | null>> {
        return this._http.post<IApiResult<{ [Key: string]: any } | null>>(this._apiUrl + '/SetPreferences', preferences)
            .pipe(
                tap(result => {
                    this._currentPreferencesBS.next(result.Result);
                }));
    }


    public RefreshCurrentConfiguration(): Observable<{ [Key: string]: any } | null> {
        return this.GetCurrentPreferences().pipe(
            map(result => {
                if (result.Code === 0) {
                    this._currentPreferencesBS.next(result.Result);
                }
                return result.Result;
            })
        );
    }




}