import { createAction, props } from '@ngrx/store';
import { IProductCatalog, IProductCatalogQuery } from '../models/product.catalog';


export const CatalogLazyFetchAllStart = createAction('[Catalog] Fetch Lazy All Start');

export const CatalogFetchAllStart = createAction('[Catalog] Fetch All Start');
export const CatalogFetchAllSuccess = createAction('[Catalog] Fetch All Success', props<{ Catalog: IProductCatalog[] }>());
export const CatalogFetchAllError = createAction('[Catalog] Fetch All Error', props<{ error: string }>());


export const CatalogItemSaveStart   = createAction('[Catalog] Save Item Start', props<{ CatalogItem: IProductCatalog }>());
export const CatalogItemSaveSuccess = createAction('[Catalog] Save Item Success', props<{ CatalogItem: IProductCatalog }>());
export const CatalogItemSaveError   = createAction('[Catalog] Save Item Error', props<{ error: string }>());


export const CatalogItemDeleteStart   = createAction('[Catalog] Delete Item Start', props<{ DeleteQuery: IProductCatalogQuery , Reason : string }>());
export const CatalogItemDeleteSuccess = createAction('[Catalog] Delete Item Success');
export const CatalogItemDeleteError   = createAction('[Catalog] Delete Item Error', props<{ error: string }>());