<ng-container *ngIf="Mode==1">
  {{ GetShipmentTypeDescription( model ) }}
</ng-container>
<ng-container *ngIf="Mode==2 && ActiveShipmentTypes">
  <select class="form-control" [formControl]="bindFormControl" tabindex="{{tabindex}}">
    <option *ngIf="!AllowUndefinedShipment" [value]="UndefinedShipmentValue">אנא בחר שיטת שילוח</option>
    <option *ngIf="!ActiveShipmentTypes[model]" disabled="disabled" [value]="model">{{GetShipmentTypeDescription(model)}}</option>
    <option *ngFor="let item of ActiveShipmentTypes | keyvalue" [value]="item.key"  >{{GetShipmentTypeDescription(item.key)}}</option>
  </select>
</ng-container>

 