import { IAliasBasicProduct, IMasterBasicOrderProduct } from './products.models';
import { IDBObject, IIdentity } from './common.models';
import { IShipment, ShipmentStatus } from './shipments.models';
import { IValidationResult } from './validation.models';
import { StoreType } from './stores';

export interface IOrderItem {
  ItemType: string,
  Quantity: number,
  ItemPrice: number,
  ItemVATPrice: number,
}

export interface IProductOrderItem extends IOrderItem {
  Product: IAliasBasicProduct | IMasterBasicOrderProduct
}

export interface IShipmentOrderItem extends IOrderItem {
  ObjectId: string,
  Name: string,
  SKU: string,
  IsOrigin: boolean
}

export interface IOrder extends IDBObject {
  SupplierNumber: string,
  State: OrderState,
  ERPOrderNumber?: string,
  ERPExportStatus: OrderErpExportStatus,
  ERPExportRemarks?: string
  CollectStatus: OrderCollectStatus,
  Shopper: IIdentity,
  Items: IOrderItem[],
  Shipments: IShipment[],
  Remarks?: string,
  OperationalRemarks?: string,
  FailedValidations: IValidationResult[],
  Source: {
    Type: OrderSource,
    OriginStore: StoreType,
    OperationId?: string,
    OperationTime?: Date
    DataSource?: string,
    SupplierStoreNumber?: string,
  },

  Club?: string | null,
  OperationsIds?: string[] | null,
  DeductedFromStock: boolean,
  Custom1?: string | null,
  Custom2?: string | null,
  Custom3?: string | null,
  Custom4?: string | null,
  Custom5?: string | null,
  Custom6?: string | null,
  Custom7?: string | null,
  Custom8?: string | null,
  Custom9?: string | null,
  // DataRetentionState? : IOrderDataRetentionStateDefinition | null,
  Metadata?: { [key: string]: string[] }
}


export enum OrderState {
  Active = 1 << 0, ActiveDescription = <any>"פעילה",
  Closed = 1 << 1, ClosedDescription = <any>"סגורה",
  Suspended = 1 << 3, SuspendedDescription = <any>"מושהית",
  OutOfStock = 1 << 4, OutOfStockDescription = <any>"חסר במלאי",
  Canceled = 1 << 6, CanceledDescription = <any>"מבוטלת",
  Draft = 1 << 10, DraftDescription = <any>"בהקמה",
}

export enum OrderSource {
  Manual = 1 << 0, ManualDescription = <any>"ידני",
  Excel = 1 << 1, ExcelDescription = <any>"קובץ אקסל",
  CSV = 1 << 2, CSVDescription = <any>"קובץ CSV",
  HTML = 1 << 3, HTMLDescription = <any>"קובץ HTML",
  Text = 1 << 4, TextDescription = <any>"קובץ טקסט"
}

export enum OrderErpExportStatus {
  Ready = 1 << 0, ReadyDescription = <any>"מוכן",
  Onhold = 1 << 1, OnholdDescription = <any>"מושהה",
  Canceled = 1 << 2, CanceledDescription = <any>"בוטל",
  Exportable = 1 << 5, ExportableDescription = <any>"העבר",
  Exported = 1 << 6, ExportedDescription = <any>"עבר",
  ExportError = 1 << 7, ExportErrorDescription = <any>"תקלה",
}


export interface IOrderDataRetentionStateDefinition {
  ShopperPIIMasked: boolean
}

export enum OrderCollectStatus {
  Ready = 1 << 0, ReadyDescription = <any>"מוכן",
  Pending = 1 << 1, PendingDescription = <any>"העבר",
  Collected = 1 << 2, CollectedDescription = <any>"עבר",
  OnHold = 1 << 3, OnHoldDescription = <any>"מושהה",
  Completed = 1 << 4, CompletedDescription = <any>"טופל",
}

export var SelectableERPStatus: OrderErpExportStatus[] = [OrderErpExportStatus.Ready, OrderErpExportStatus.Exportable, OrderErpExportStatus.Onhold, OrderErpExportStatus.Canceled];

