<div class="d-flex justify-content-center" *ngIf="!CatalogForm">
    <i class="fa fa-spinner fa-pulse fa-5x fa-fw mt-5 text-secondary"></i>
</div>


<form *ngIf="CatalogForm" [formGroup]="CatalogForm" (ngSubmit)="onSubmit()" class="container-fluid mt-4">

    <div class="row">
        <div class="col">
            <labeled-textbox type="text" title="שם קטלוג" [bindFormControl]="CatalogForm.controls.Name" [showValidIndicator]="CatalogForm.controls.Name.touched" [validationMessages]="{'required': 'שדה חובה'}"></labeled-textbox>
        </div>
        <div class="col">
            <labeled-textbox type="text" title="קוד" [bindFormControl]="CatalogForm.controls.Code" [showValidIndicator]="CatalogForm.controls.Code.touched" [validationMessages]="{'required': 'שדה חובה'}"></labeled-textbox>
        </div>

        <div class="col">
            <ngx-bootstrap-multiselect [options]="StoreMultiSelectOptions" [texts]="StoreMultiSelectTexts" [settings]="StoreMultiSelectSettings" formControlName="Stores"> </ngx-bootstrap-multiselect>
            <div class="invalid-feedback d-block" *ngIf="CatalogForm.controls.Stores.invalid">חובה לבחור חנות אחת לפחות</div>
        </div>
    </div>


    <div class="row border-bottom border-dark mt-4 mb-2">
        <h2 class="col-6">
            שדות בקטלוג
        </h2>
        <div class="col-6 text-right p-2">
            <button type="button" class='btn btn-outline-success btn-sm' (click)="AddCatalogField()">
                הוסף שדה <i class="fas fa-plus"></i>
            </button>

            <button type="button" class='btn btn-outline-success btn-sm ml-2' (click)="open(importCatalogWizard,{ size: 'xl' },null,null)"> יבא מקובץ</button>

        </div>
    </div>
    <div class="row">
        <div class="col">
            <ng-container formArrayName="Fields">
                <table class="table table-hover table-sm">
                    <thead>
                        <tr>
                            <th scope="col">שם</th>
                            <th scope="col">סוג</th>
                            <th scope="col">קוד</th>
                            <th scope="col">מאפינים</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let catalogFieldForm of CatalogFieldsForm.controls; let i = index">
                            <tr [formGroup]="catalogFieldForm">
                                <td>
                                    <labeled-textbox type="text" classes="mb-0" [bindFormControl]="catalogFieldForm.controls.Name" [showValidIndicator]="catalogFieldForm.controls?.Name?.touched" [validationMessages]="{'required': 'שדה חובה'}"></labeled-textbox>

                                </td>

                                <td>
                                    <select class="form-control" formControlName="ItemType">
                                        <option value="ProductCatalogTextField">טקסט</option>
                                        <option value="ProductCatalogReferenceField">שדה מוצר</option>
                                    </select>
                                </td>
                                <td>
                                    <labeled-textbox type="text" classes="m-0" [bindFormControl]="catalogFieldForm.controls.Code" [showValidIndicator]="catalogFieldForm.controls?.Code?.touched" [validationMessages]="{'required': 'שדה חובה'}"></labeled-textbox>
                                </td>
                                <td>
                                    <div *ngIf="catalogFieldForm.controls.ItemType.value == 'ProductCatalogReferenceField'" class="d-flex ">
                                        <div class="align-self-center mr-2">
                                            שדה מוצר
                                        </div>
                                        <div class="flex-grow-1">
                                            <select class="form-control" formControlName="ReferenceField" [ngClass]="{'is-invalid' : catalogFieldForm.controls.ReferenceField?.invalid ,'is-valid' : catalogFieldForm.controls.ReferenceField?.valid }">
                                                <option value="SKU">מק"ט</option>
                                                <option value="UPC">מק"ט יצרן</option>
                                                <option value="Name">שם</option>
                                                <option value="Model">מודל</option>
                                                <option value="Category">קטגוריה</option>
                                                <option value="Media">תמונות</option>
                                                <option value="PriceList.RecommendedPrice">מחיר</option>
                                                <option value="Description">תיאור</option>
                                                <option value="Warranty">אחריות</option>
                                            </select>
                                        </div>
                                    </div>


                                </td>
                                <td class="text-right">
                                    <button type="button" class="btn btn-outline-danger" (click)="DeleteCatalogField(i)">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </ng-container>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="mt-4">
                <button type="submit" class="btn btn-outline-primary" [disabled]="!CatalogForm?.valid">שמור</button>
            </div>
        </div>
    </div>
</form>



<ng-template #importCatalogWizard let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">אשף ייבוא קטלוג</h4>
        <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <import-catalog-wizard [Catalog]="CatalogForm.value" (CatalogChange)="c(ImportCatalogWizardSave($event))"></import-catalog-wizard>
    </div>




</ng-template>