import { Component, EventEmitter, Output, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { CommonService } from '../../../services/common.service';
import { IIdentity } from '../../../models/common.models';
import { UserControlEditorMode } from '../../../models/user-controls';
import { FormControlStatus, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'identity-editor',
  templateUrl: './identity.editor.usercontrol.component.html',
  styleUrls: ['./identity.editor.usercontrol.component.css']
})
export class IdentityEditorUserControlComponent implements OnInit, OnChanges {


  constructor(private _common: CommonService) { }

  @Output() OnValidChange: EventEmitter<FormControlStatus> = new EventEmitter<FormControlStatus>();
  @Output() IdentityChange: EventEmitter<IIdentity> = new EventEmitter<IIdentity>();
  @Input() Identity!: IIdentity;
  @Input() Mode!: UserControlEditorMode;

  public ValidState!: FormControlStatus;
  public ValidationMessage!: string;
  public ValidationFrom: UntypedFormGroup;





  ngOnInit(): void {
    if (!this.Mode) this.Mode = UserControlEditorMode.View;


    if (!this.Identity) this.Identity = this._common.CreateNewIIdentity();


    this.bindControls();

  }
  private bindControls() {

    this.ValidationFrom = new UntypedFormGroup({
      Email: new UntypedFormControl(this.Identity.Email, Validators.email),
      Phone: new UntypedFormControl(this.Identity.Phone),
      FullName: new UntypedFormControl(this.Identity.FullName),
    });

    this.ValidationFrom.statusChanges.subscribe(
      r => {
        this.OnValidChange.emit(r); // TODO : check what return here 
      });
    this.ValidationFrom.valueChanges.subscribe(value => {
      this.Identity = value;
      this.IdentityChange.emit(this.Identity);
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Identity && !changes.Identity.firstChange) this.IdentityChange.emit(this.Identity);
  }


}

