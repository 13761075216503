import { Pipe, PipeTransform } from '@angular/core';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'toAge' })
export class ToAge implements PipeTransform {


  transform(value: string | Date): string {


    var date: Date;
    if (value instanceof Date) {
      date = value;
    }
    else {
      date = new Date(value);
    }


    var dif = (new Date()).getTime() - date.getTime();

    var seconds_from_T1_to_T2 = Math.abs(dif / 1000);
    var hours_from_T1_to_T2 = (seconds_from_T1_to_T2 / (60 * 60));
    var days_from_T1_to_T2 = (hours_from_T1_to_T2 / 24);
    var weeks_from_T1_to_T2 = (days_from_T1_to_T2 / 7);
    var months_from_T1_to_T2 = (days_from_T1_to_T2 / (365 / 12));
    var year_from_T1_to_T2 = (days_from_T1_to_T2 / 365);

    var hours = Math.floor(hours_from_T1_to_T2 % 24);
    var day = Math.floor(days_from_T1_to_T2 % 7);
    var week = Math.floor(weeks_from_T1_to_T2 % 5);
    var months = Math.floor(months_from_T1_to_T2 % 12);
    var years = Math.floor(year_from_T1_to_T2);

    var result = "";

    if (years || months || week) {
      if (years) result += ' Y' + years;
      if (months) result += ' M' + months;
      if (week) result += ' W' + week;
      if (day) result += ' D' + day;
    } else {
      if (day) result += ' D' + day;
      if (hours) result += ' H' + hours;
    }



    return result.substr(1);

  }
}