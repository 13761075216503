<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <h1>קביעת פורמט קובץ ERP</h1>
      <p>
        כאן תוכל לטעון קובץ (<b>אקסל או XML </b>) לקביעת המבנה של קובץ -ERP לפיו ייוצאו ההזמנות. <br /> להסבר על קביעת הפורמט והגדרתו, יש לפנות לצוות SendE.
      </p>

      <p style="width: max-content;">
        <uploadfile [mode]="1" [model]="Files()" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xml" (modelChange)="OnFileUploaded($event)"> </uploadfile>
      </p>
      <p class="d-flex justify-content-start" *ngIf="Templates && Templates[0]">
        <labeled-textbox class="w-75" type="text" title="שם קובץ לשמירה" [(model)]="Templates[0].FileName"></labeled-textbox>
        <label class="form-group has-float-label text-right w-25">
          <select class="form-control" [(ngModel)]="Templates[0].FileType" *ngIf="Templates[0].FileType != 64;else fileTypeDescription">
            <option *ngFor="let item of ExportFileTypes" [value]="item">{{item | enumDescription : "FileType" }}</option>
          </select>
          <ng-template #fileTypeDescription>
            <span class="d-block mt-2 ml-4 pl-2 font-weight-bold" style="text-align: right;">
              {{Templates[0].FileType | enumDescription : "FileType" }}
            </span>

          </ng-template>
          <span class="label">סוג קובץ</span>
        </label>
      </p>
      <p>
        <button type="button" tabindex="1000" class="btn btn-lg btn-outline-success" (click)="SaveSupplierExportsAndReturn();">
          <h4 class="d-inline-block mr-2">שמור</h4>
          <i class="fa fa-save"></i>
        </button>
      </p>
    </div>
    <div class="col-6 border-right border-dark border-3" *ngIf="LocalERPConfiguration">
      <h1>התממשקות ישירה למערכת ERP</h1>
      <p>
        מערכת SendE מאפשרת עבודה ישירה מול מערכת ה-ERP שלך במידה ונמצא Agent מתאים עבור. Agent היא תוכנה ייעודית המקשרת בין SendE למערכת ה-ERP שלך.
      </p>
      <ng-container *ngIf="ErpUserName">
        <p>
          כדי לחבר ישירות בין המערכות יש להזין את הקוד הייחודי שלך שלהלן במהלך ההתקנה של ה-Agent:
        </p>
        <div class="text-center">
          <span class="border border-dark rounded px-2">{{ErpUserName}}</span>
          <p class="text-danger font-weight-bold">
            להתקנת ה-Agent עבור מערכת ה-ERP שלך - יש לצור קשר עם התמיכה של SendE
          </p>
        </div>
      </ng-container>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 mb-4">
            <span class="mr-2">פעיל</span>
            <checkbox [(model)]="LocalERPConfiguration.Active"></checkbox>
          </div>

          <div class="col-6 mb-4">
            <label class="form-group has-float-label text-right">
              <select class="form-control" [(ngModel)]="LocalERPConfiguration.Type">
                <option *ngFor="let item of 'ERPType' | enumList" [value]="item.id">{{item.name}}</option>
              </select>
              <span class="label">סוג ERP</span>
            </label>

          </div>
        </div>
        <ng-container *ngIf="![5,6,'5','6'].includes(  LocalERPConfiguration.Type )">
          <div class="row">
            <div class="col-6">
              <label class="form-group has-float-label text-right">
                <select class="form-control" [(ngModel)]="LocalERPConfiguration.DatabaseType">
                  <option *ngFor="let item of ERPDBTypeOptions" [value]="item.id">{{item.name}}</option>
                </select>
                <span class="label">סוג בסיס הנתונים</span>
              </label>
            </div>
            <div class="col-6">
              <labeled-textbox type="text" title="בסיס נתונים" [(model)]="LocalERPConfiguration.Database"></labeled-textbox>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <labeled-textbox type="text" title="שרת נוכחי" [(model)]="LocalERPConfiguration.Server"></labeled-textbox>
            </div>
            <div class="col-6">

            </div>
          </div>
        </ng-container>
        <ng-container>
          <div class="row">
            <div class="col-6" *ngIf="HTMLHelper.GetLocalERPFieldDescription('CustomerId')!== ''">
              <labeled-textbox type="text" [title]="HTMLHelper.GetLocalERPFieldDescription('CustomerId') ?? 'מזהה/מספר לקוח'" [(model)]="LocalERPConfiguration.CustomerId"></labeled-textbox>
            </div>
            <div class="col-6">
              <labeled-textbox type="text" [title]="HTMLHelper.GetLocalERPFieldDescription('Token') ?? 'טוקן'" [(model)]="LocalERPConfiguration.Token"></labeled-textbox>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col-6" *ngIf="HTMLHelper.GetLocalERPFieldDescription('Username') !== ''">
            <labeled-textbox type="text" [title]="HTMLHelper.GetLocalERPFieldDescription('Username') ?? 'קוד משתמש' " [(model)]="LocalERPConfiguration.Username"></labeled-textbox>
          </div>
          <div class="col-6" *ngIf="HTMLHelper.GetLocalERPFieldDescription('Password') !== ''">
            <labeled-textbox type="password" [title]="HTMLHelper.GetLocalERPFieldDescription('Password') ?? 'סיסמה'" [(model)]="LocalERPConfiguration.Password"></labeled-textbox>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 1" [(model)]="LocalERPConfiguration.Param01"></labeled-textbox>
          </div>
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 2" [(model)]="LocalERPConfiguration.Param02"></labeled-textbox>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 3" [(model)]="LocalERPConfiguration.Param03"></labeled-textbox>
          </div>
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 4" [(model)]="LocalERPConfiguration.Param04"></labeled-textbox>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 5" [(model)]="LocalERPConfiguration.Param05"></labeled-textbox>
          </div>
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 6" [(model)]="LocalERPConfiguration.Param06"></labeled-textbox>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 7" [(model)]="LocalERPConfiguration.Param07"></labeled-textbox>
          </div>
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 8" [(model)]="LocalERPConfiguration.Param08"></labeled-textbox>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 9" [(model)]="LocalERPConfiguration.Param09"></labeled-textbox>
          </div>
          <div class="col-6">
            <labeled-textbox type="text" title="פרמטר 10" [(model)]="LocalERPConfiguration.Param10"></labeled-textbox>
          </div>
        </div>
      </div>


      <div class=" mb-4 mr-2">
        <span class="mr-2">העבר שורת משלוח</span>
        <checkbox [(model)]="LocalERPConfiguration.IncludeShipmentItems"></checkbox>
      </div>
      <div class="">
        <button class="btn btn-outline-primary" (click)="open(editErpFieldMapping,{ size: 'xl' })">מיפוי שדות</button>
      </div>
      <p class="mt-4">
        <button type="button" tabindex="1000" class="btn btn-lg btn-outline-success" (click)="SaveSupplierLocalERPConfiguration();">
          <h4 class="d-inline-block mr-2">שמור</h4>
          <i class="fa fa-save"></i>
        </button>
      </p>
    </div>
  </div>
</div>




<ng-template #editErpFieldMapping let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">מיפוי שדות הזמנה למערכת ERP</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body px-0">
    <div class="container-fluid">
      <div class="row font-weight-bold border-bottom my-2">
        <div class="col-12">הוסף שדה חדש</div>
      </div>
      <div class="row">
        <div class="col-6">

          <labeled-autocomple-textbox class="compact autocomplate-dropdown-menu-h150-scrollable" [(model)]="SelectedAddSendEMappingField" [CngbTypeahead]="SelectedAddSendeMappingSearch" OpenOnFocus="true">
          </labeled-autocomple-textbox>

        </div>
        <div class="col-4">

          <labeled-autocomple-textbox class="compact autocomplate-dropdown-menu-h150-scrollable" [(model)]="SelectedAddERPMappingField" [CngbTypeahead]="SelectedAddERPMappingSearch" OpenOnFocus="true">
          </labeled-autocomple-textbox>

        </div>
        <div class="col-2">
          <button type="button" class="btn btn-outline-success" [disabled]="!SelectedAddERPMappingField || !SelectedAddSendEMappingField || LocalERPConfiguration.FieldMapping[SelectedAddERPMappingField]" (click)="AddNewErpMapping(); ">
            <span class="mr-2">הוסף</span> <i class="fas fa-plus-square"></i>
          </button>
        </div>
        <div class="col-4 offset-6 text-danger" *ngIf="LocalERPConfiguration.FieldMapping[SelectedAddERPMappingField]">* שדה {{SelectedAddERPMappingField}} כבר קיים במיפוי</div>
      </div>

      <div class="row font-weight-bold border-bottom ">

        <div class="col-6 text-center">שדה בהזמנה</div>
        <div class="col-4 text-center">שדה במערכת ERP</div>
      </div>
      <div class="row py-1 border-top border-light" *ngFor="let map of LocalERPConfiguration.FieldMapping | keyvalue;  index as i;  ">
        <div class="col-6">{{SendEMappingFields[map.value] ? SendEMappingFields[map.value] + ' (' + map.value + ')' : map.value}}</div>
        <div class="col-4"> {{map.key}}</div>
        <div class="col-2">
          <button type="button" class="btn btn-outline-danger" (click)="DeleteErpMapping(map.key);">
            <span class="">מחק</span> <i class="far fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('save')">סגור</button>
  </div>

</ng-template>