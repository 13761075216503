import { Component, EventEmitter, Output, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IProduct, IAliasProduct, IMasterAliasProductRelationship, IProductRef, IMasterProduct, IMasterProductMatchResult, IMasterMatchResult, IMasterRemarkMatchResult, IMasterIgnoreMatchResult, IAliasBasicProduct, IPriceListItem, IMasterShipMethodMatchResult, IMasterBasicMatchProduct, getMasterProductStoreValue, setMasterProductStoreValue } from '../../../models/products.models';
import { ProductService, IProductSearchRequest } from '../../../services/product.service';

import { filter, first, last, map, reduce } from 'rxjs/operators';

import { ShipmentService } from '../../../services/shipment.service';
import { Observable, lastValueFrom } from 'rxjs';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { IStoreDetails, StoreType } from '../../../models/stores';
import { RoundNumber } from 'src/app/services/common.service';
import { AdminService } from 'src/app/services/admin.service';
import { ISupplierConfiguration, ISupplierStoreConfiguration } from 'src/app/models/suppliers';
import { UndefinedShipmentType } from 'src/app/models/shipments.models';
import { FormControlStatus } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ConfigStateFeature } from 'src/app/state/config.reducer';
import { get } from 'jquery';
import { StoreService } from 'src/app/services/store.service';


@Component({
  selector: 'master-alias-product-binder',
  templateUrl: './masteraliasproductbinder.usercontrol.component.html',
  styleUrls: ['./masteraliasproductbinder.usercontrol.component.css']
})

export class MasterAliasProductBinderUserControlComponent implements OnInit, OnChanges {


  @Input()
  public Products!: IProductRef<IAliasBasicProduct>[];

  @Input()
  public Source!: StoreType;

  @Input()
  public OrderId!: string;

  @Input()
  public tabindex: number = 0;

  @Output()
  public OnValidChange: EventEmitter<FormControlStatus> = new EventEmitter<FormControlStatus>();

  @Output()
  public OnRelationshipsChange: EventEmitter<IMasterAliasProductRelationship[]> = new EventEmitter<IMasterAliasProductRelationship[]>();

  public ValidState!: FormControlStatus;

  public Relationships!: IMasterAliasProductRelationship[]
  public RelationshipsTable!: HtmlRelationshipsRecordTable[];
  public MasterRelationshipsIndexs!: number[];
  private SupplierConfig: ISupplierConfiguration;
  private get SupplierStoreConfig(): ISupplierStoreConfiguration {
    return this.SupplierConfig.Stores[StoreType[this.Source]];
  }
  private
  public get IsStockEnabled(): boolean {
    return !!(this.SupplierConfig?.StockSetting?.Enable);
  }

  public get IsPriceListOverride() {
    return this.SupplierStoreConfig?.ImportConfiguration?.OverridePriceList;
  }

  public StoreDetails: IStoreDetails

  constructor(private _productService: ProductService, private _shipmentService: ShipmentService, private _admin: AdminService, private _storeService: StoreService, private store: Store) { }

  private masterExtraDataDictionary: { [key: string]: MasterExtraData }
  ngOnInit(): void {
    this.OnRelationshipsChange.subscribe(Relationships => {
      this.CheckValidation();
    });

    this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)).subscribe(conf => {
      this.SupplierConfig = conf;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setValidState('PENDING');
    this.buildProductRelationships();
    lastValueFrom(this._storeService.GetStoreDetails(this.Source)).then(store => {
      this.StoreDetails = store;
    });
  }

  private setValidState(result: FormControlStatus) {
    if (this.ValidState != result) {
      this.OnValidChange.emit(result);
    }
    this.ValidState = result;
  }
  public CheckValidation() {
    this.setValidState(this.CalcValidation());
  }

  public CalcValidation(): FormControlStatus {
    if (!this.Relationships?.length) return `INVALID`;
    if (this.Relationships) {
      for (var i = 0; i < this.Relationships.length; i++) {
        for (var j = 0; j < this.Relationships[i].MasterMatches.length; j++) {
          if (this.Relationships[i].MasterMatches[j].ItemType == "ProductMatch") {
            const productMatch = this.Relationships[i].MasterMatches[j] as IMasterProductMatchResult;
            if (!productMatch.Product.SKU) return 'INVALID';
            if (!productMatch.Product.Name) return 'INVALID';
          }
        }
      }
    }

    return 'VALID';
  }


  public GetMasterExtraDataDictionary(master: IMasterBasicMatchProduct): MasterExtraData {


    if (master?.Id) return this.masterExtraDataDictionary[master.Id] ?? null;
    else if (master?.SKU) return this.masterExtraDataDictionary[master.SKU?.trim()] ?? null;
    else if (master?.Name) return this.masterExtraDataDictionary[master.Name?.trim()] ?? null;
    else return null;

  }

  public SetMasterExtraDataDictionary(master: IMasterBasicMatchProduct, value: any) {
    console.log(value);
    if (!value) return;
    var data = this.GetMasterExtraDataDictionary(master);
    if (!data) { // if not exists add to dictionary 
      data = { isNew: true }; // default value
      if (master?.Id) this.masterExtraDataDictionary[master.Id] = data;
      else if (master.SKU) this.masterExtraDataDictionary[master.SKU?.trim()] = data;
      else if (master.Name) this.masterExtraDataDictionary[master.Name?.trim()] = data;
      else return;
    }

    if (Object.prototype.hasOwnProperty.call(value, "name")) data.name = value.name;
    if (Object.prototype.hasOwnProperty.call(value, "isNew")) data.isNew = !!(value.isNew);
    if (Object.prototype.hasOwnProperty.call(value, "stockAmount")) data.stockAmount = Number(value.stockAmount);
    if (Object.prototype.hasOwnProperty.call(value, "defaultShipmentMethod")) data.defaultShipmentMethod = value.defaultShipmentMethod;
    if (Object.prototype.hasOwnProperty.call(value, "priceList")) data.priceList = Number(value.priceList);

  }

  public GetMasterExtraDataById(id: string): MasterExtraData {
    return this.masterExtraDataDictionary[id];
  }


  private async buildProductRelationships() {
    this.Relationships = [];

    if (this.Products) {

      var r = await lastValueFrom(this._productService.MatchProducts(this.Products, this.Source));
      if (r.Code == 0) {
        this.Relationships = r.Result;
        this.fillMissingMasterMatches();
        await this.buildMasterDetails();
        this.RaiseOnRelationshipsChange();
        this.bindControls();

      }

    }
  }

  private bindControls() {
    this.BuildHtmlTableList();
  }

  private fillMissingMasterMatches() {
    /*
        if (!this.Relationships.length) {
          this.Relationships = [{
            MasterMatches: [],
            AliasProducts: [],
            OriginStore : this.Source,
            Id : null
          }]
        }
    */
    for (let i = 0; i < this.Relationships.length; i++) {
      if (!(this.Relationships[i].MasterMatches && this.Relationships[i].MasterMatches.length)) this.Relationships[i].MasterMatches =
        [{
          Product: this._productService.CreateNewMasterBasicMatchProduct(),
          Quantity: 0,
          ItemType: "ProductMatch"
        } as IMasterProductMatchResult];

      for (let j = 0; j < this.Relationships[i].MasterMatches.length; j++) {
        if (this.Relationships[i].MasterMatches[j].ItemType == "ProductMatch") {
          const productMatch = this.Relationships[i].MasterMatches[j] as IMasterProductMatchResult;

          if (!productMatch.Product) productMatch.Product = this._productService.CreateNewMasterBasicMatchProduct();

          if (!productMatch.Quantity) {
            if (j < (this.Relationships[i].AliasProducts || []).length) {
              productMatch.Quantity = this.Relationships[i].AliasProducts[j].Quantity;
            }
            else productMatch.Quantity = this.Relationships[i].AliasProducts[this.Relationships[i].AliasProducts.length - 1].Quantity;
          }
        }
      }
    }
  }

  private async buildMasterDetails() {
    this.masterExtraDataDictionary = {};
    var productsMasters: IMasterProductMatchResult[] = [];
    this.Relationships.forEach(relationship => {

      relationship.MasterMatches.forEach(master => {
        if (master.ItemType == "ProductMatch") {
          var masterProduct = master as IMasterProductMatchResult;
          if (masterProduct?.Product?.Id) productsMasters.push(masterProduct)
        }
      });
    });

    if (productsMasters.length) {
      var dbProducts = await this._productService.SearchProducts(
        {
          Ids: productsMasters.map(mp => mp.Product.Id),
          Type: "MasterProduct",
          PageSize: 100
        }
      ).toPromise();

      if (dbProducts.Result.Items) {
        dbProducts.Result.Items.forEach(product => {
          let productsMaster = productsMasters.find(pm => pm.Product.Id == product.Id)
          if (productsMaster.Product) this.setDataFromDBProduct(productsMaster.Product, product as IMasterProduct);
        });
      }
    }
  }

  private setDataFromDBProduct(masterMatch: IMasterBasicMatchProduct, dbMasterProduct: IMasterProduct) {

    let extraData = {
      id: dbMasterProduct.Id,
      defaultShipmentMethod: dbMasterProduct.DefaultShipmentMethod,
      isNew: !dbMasterProduct?.Id
    }

    if (dbMasterProduct.Stock?.Amount || dbMasterProduct.Stock?.Amount === 0) extraData['stockAmount'] = dbMasterProduct.Stock.Amount;
    if (getMasterProductStoreValue(dbMasterProduct, this.Source, "PriceList")?.RecommendedPrice || getMasterProductStoreValue(dbMasterProduct, this.Source, "PriceList")?.RecommendedPrice === 0) extraData['priceList'] = getMasterProductStoreValue(dbMasterProduct, this.Source, "PriceList").RecommendedPrice;

    this.SetMasterExtraDataDictionary(masterMatch, extraData);
  }

  public BindMasterProductToMatch(match: IMasterProductMatchResult, product: IMasterProduct, PreventNewProductOverride: boolean = false) {
    if (PreventNewProductOverride && match.Product && (!product?.Id)) return;
    match.Product = this._productService.CreateNewMasterBasicMatchProduct(product);
    this.setDataFromDBProduct(match.Product, product);
  }




  public async Save(): Promise<IMasterAliasProductRelationship[] | null> {
    if (this.ValidState == 'VALID') {



      var result = await Promise.all(
        this.Relationships.map(r =>
          lastValueFrom(this._productService.SaveMasterAliasProductRelationship(r)
            .pipe(
              map(r => r.Result)
            ))
        ));

      if (result?.length) {

        var productsMasters: IMasterProductMatchResult[] = [];
        result.forEach(relationship => {
          relationship.MasterMatches.forEach(master => {
            if (master.ItemType == "ProductMatch") {
              var masterProduct = master as IMasterProductMatchResult;
              if (masterProduct?.Product?.Id) productsMasters.push(masterProduct)
            }
          });
        });

        if (productsMasters.length) {
          var dbProducts = await lastValueFrom(this._productService.SearchProducts(
            {
              Ids: productsMasters.map(mp => mp.Product.Id),
              Type: "MasterProduct",
              PageSize: 100
            }
          ));


          var updateTasks: Promise<boolean>[] = [];

          result.forEach(r => {
            r.MasterMatches.forEach(master => {
              if (master.ItemType == "ProductMatch") {
                var masterProduct = master as IMasterProductMatchResult;
                const data = this.GetMasterExtraDataDictionary(masterProduct.Product);
                if (data && masterProduct?.Product?.Id) {
                  var saveProduct = false;
                  var product = dbProducts.Result.Items.find(p => p.Id == masterProduct.Product.Id) as IMasterProduct;

                  if (!data.isNew && (data.defaultShipmentMethod)) {
                    product.DefaultShipmentMethod = data.defaultShipmentMethod;
                    saveProduct = true;
                  }

                  if (!data.isNew && data.name && product.Name != data.name) {
                    product.Name = data.name;
                    saveProduct = true;
                  }

                  if (data.priceList || data.priceList === 0) {
                    let priceList = getMasterProductStoreValue(product, this.Source, "PriceList") ?? {} as IPriceListItem;
                    priceList.RecommendedPrice = data.priceList;
                    setMasterProductStoreValue(product, this.Source, "PriceList", priceList);
                    saveProduct = true;
                  }


                  if (saveProduct) {
                    updateTasks.push(lastValueFrom(this._productService.SaveProduct(product)).then(p => !!p.Result));
                  }
                  if (this.SupplierConfig.StockSetting?.Enable) {
                    updateTasks.push(lastValueFrom(this._productService.SetProductStock(masterProduct?.Product?.Id, {
                      Amount: data.stockAmount
                    })).then(p => p.Result));
                  }
                }
              }
            });
          });

          await Promise.all(updateTasks);
        }


      }

      return result;

    }
    else return null;

  }

  public GetMasterName(itemType: string) {
    if (itemType == "RemarkMatch") return "הערה";
    if (itemType == "ShipMethodMatch") return "שיטת משלוח";
    else return "פריט";
  }

  public BuildHtmlTableList() {
    this.RelationshipsTable = [];

    for (var i = 0; i < this.Relationships.length; i++) {
      for (var j = 0; j < this.Relationships[i].AliasProducts.length; j++) {

        this.RelationshipsTable.push({
          Alias: this.Relationships[i].AliasProducts[j],
          Relationships: this.Relationships[i],
          RowNum: 0,
          MasterRecord: 0
        });
      }
    }

    this.RelationshipsTable = this.RelationshipsTable.sort(
      (rel1, rel2) => {
        var num1 = (rel1.Alias && rel1.Alias.Product ? rel1.Alias.OrderRowIndex || 0 : 0);
        var num2 = (rel2.Alias && rel2.Alias.Product ? rel2.Alias.OrderRowIndex || 0 : 0);
        return num1 - num2;
      });




    for (var i = 0; i < this.RelationshipsTable.length; i++) {
      this.RelationshipsTable[i].RowNum = i + 1;
    }

    for (var i = 0; i < this.RelationshipsTable.length; i++) {
      this.RelationshipsTable[i].MasterRecord = (this.RelationshipsTable[i].Alias === this.RelationshipsTable[i].Relationships.AliasProducts[0]) ? this.RelationshipsTable[i].RowNum : 0;
    }

    this.MasterRelationshipsIndexs = [];
    for (var i = 0; i < this.RelationshipsTable.length; i++) {
      if (this.RelationshipsTable[i].MasterRecord === this.RelationshipsTable[i].RowNum) {
        this.MasterRelationshipsIndexs.push(this.RelationshipsTable[i].RowNum);
      }
      else {
        let masterRecord = this.RelationshipsTable.find(r => r.RowNum == r.MasterRecord && r.Relationships.AliasProducts.indexOf(this.RelationshipsTable[i].Alias) >= 0);
        if (masterRecord) this.RelationshipsTable[i].MasterRecord = masterRecord.RowNum;
      }
    }

  }

  public UnionAlias(rel: HtmlRelationshipsRecordTable, destRowNum: number) {

    let destRel!: IMasterAliasProductRelationship;
    let prevRel = this.Relationships.find((r) => r.AliasProducts.indexOf(rel.Alias) >= 0) || rel.Relationships;

    if (destRowNum == rel.RowNum) { // split (return back to be a master record )
      destRel = this._productService.CreateNewMasterAliasProductRelationship(
        this.Source,
        [{
          Product: this._productService.CreateNewMasterBasicMatchProduct(),
          Quantity: rel.Alias.Quantity,
          ItemType: "ProductMatch"
        } as IMasterMatchResult]
        , [])
      this.Relationships.push(destRel);
    }
    else {
      destRel = this.RelationshipsTable[destRowNum - 1].Relationships;
    }

    // remove from source.
    let aliasIndex1 = prevRel.AliasProducts.indexOf(rel.Alias);
    if (aliasIndex1 >= 0) prevRel.AliasProducts.splice(aliasIndex1, 1);

    // add the  alias
    let aliasIndex2 = destRel.AliasProducts.indexOf(rel.Alias);
    if (aliasIndex2 < 0) destRel.AliasProducts.push(rel.Alias);

    this.Relationships = this.Relationships.filter(r2 => r2.AliasProducts.length > 0);

    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }

  public AddNewMasterProduct(rel: IMasterAliasProductRelationship, index: number) {
    rel.MasterMatches.splice(index, 0, {
      Product: this._productService.CreateNewMasterBasicMatchProduct(),
      Quantity: (rel.MasterMatches[0] as IMasterProductMatchResult).Quantity,
      ItemType: "ProductMatch"
    } as IMasterProductMatchResult);
    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }
  public AddNewMasterRemark(rel: IMasterAliasProductRelationship, index: number) {
    rel.MasterMatches.splice(index, 0, {
      ItemType: "RemarkMatch",
      Remark: rel.AliasProducts[0].Product.Name
    } as IMasterRemarkMatchResult);
    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }

  public AddNewMasterShipMethod(rel: IMasterAliasProductRelationship, index: number) {
    rel.MasterMatches.splice(index, 0, {
      ItemType: "ShipMethodMatch",
      ShipMethod: UndefinedShipmentType
    } as IMasterShipMethodMatchResult);
    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }

  public DeleteNewMaster(rel: IMasterAliasProductRelationship, index: number) {
    rel.MasterMatches.splice(index, 1);
    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }

  public ConvertToProductMatch(rel: IMasterAliasProductRelationship, index: number) {
    let quantity = 1;
    const masterproducts = rel.MasterMatches.filter(i => i.ItemType == "ProductMatch") as IMasterProductMatchResult[];
    if (masterproducts && masterproducts.length) quantity = masterproducts[0].Quantity;
    else quantity = rel.AliasProducts[0].Quantity;

    rel.MasterMatches[index] = {
      Product: this._productService.CreateNewMasterBasicMatchProduct(),
      Quantity: quantity,
      ItemType: "ProductMatch"
    } as IMasterProductMatchResult;

    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }

  public ConvertToRemarkMatch(rel: IMasterAliasProductRelationship, index: number) {
    rel.MasterMatches[index] = {
      ItemType: "RemarkMatch",
      Remark: rel.AliasProducts[0].Product.Name
    } as IMasterRemarkMatchResult;
    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }

  public ConvertToShipMethodMatch(rel: IMasterAliasProductRelationship, index: number) {
    rel.MasterMatches[index] = {
      ItemType: "ShipMethodMatch",
      ShipMethod: UndefinedShipmentType
    } as IMasterShipMethodMatchResult;
    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }

  public ConvertToIgnoreMatch(rel: IMasterAliasProductRelationship, index: number) {
    rel.MasterMatches[index] = {
      ItemType: "IgnoreMatch"
    } as IMasterIgnoreMatchResult;
    this.RaiseOnRelationshipsChange();
    this.bindControls();
  }

  public RaiseOnRelationshipsChange() {
    this.OnRelationshipsChange.next(this.Relationships);
  }

  public SetMasterItemPrice(master: IMasterProductMatchResult, itemPrice) {
    if (isNaN(itemPrice)) {
      master.ItemPrice = undefined;
      master.ItemVATPrice = undefined;
    }
    else {
      const VatRate = this.SupplierConfig.VatRate;
      master.ItemPrice = RoundNumber(itemPrice / (1 + VatRate), 2);
      master.ItemVATPrice = RoundNumber(itemPrice - master.ItemPrice, 2);

    }
  }



  public GetMainMasterProductRefTotalPrice(relationship: IMasterAliasProductRelationship): number {

    var totalAliasPrice = relationship.AliasProducts.reduce((aggSum: number, alias: IProductRef<IAliasBasicProduct>) => aggSum + (((alias?.ItemPrice ?? 0) + (alias?.ItemVATPrice ?? 0)) * alias.Quantity), 0);
    return relationship.MasterMatches.reduce((aggSub: number, master: IMasterMatchResult, index: Number) => {
      if (index == 0 || master.ItemType != "ProductMatch") return aggSub;
      else return aggSub - ((((master as IMasterProductMatchResult)?.ItemPrice ?? 0) + ((master as IMasterProductMatchResult)?.ItemVATPrice ?? 0)) * (master as IMasterProductMatchResult).Quantity);
    }, totalAliasPrice as number);
  }


  public SetOrderBind(relationship: IMasterAliasProductRelationship, value: boolean) {
    relationship.OrderBind = value ? null : this.OrderId;
  }


}



interface HtmlRelationshipsRecordTable {
  Relationships: IMasterAliasProductRelationship,
  Alias: IProductRef<IAliasBasicProduct>,
  RowNum: number,
  MasterRecord: number
}


interface MasterExtraData {
  stockAmount?: number
  priceList?: number
  defaultShipmentMethod?: string
  isNew: boolean,
  name?: string
}