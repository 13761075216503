import { Component, Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

/**
 * Example of a Native Date adapter
 */
@Injectable() // TODO FIX the day delay issue.
export class NgbDateNativeAdapter extends NgbDateAdapter<string | null> {

  fromModel(date: string | null): NgbDateStruct {
    if (date) {
      var d = new Date(Date.parse(date));
      return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
    }
    else {
      return { year: 0, month: 0, day: 0 };
    }
  }

  toModel(date: NgbDateStruct): string | null {
    return date ? new Date(date.year, date.month - 1, date.day).toISOString() : null;
  }
}


@Injectable() // TODO FIX the day delay issue.
export class NgbParserNativeAdapter extends NgbDateParserFormatter {
  private regex = /^(\d\d?)\/(\d\d?)\/(\d{2}(\d{2})?)$/

  parse(value: string): NgbDateStruct {

    var match = this.regex.exec(value);
    if (match) {
      return {
        day: parseInt(match[1]),
        month: parseInt(match[2]),
        year: parseInt(match[3])
      };
    } else

      var d = new Date();
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };

  }
  format(date: NgbDateStruct): string {
    if (date) return date.day + '/' + date.month + '/' + date.year;
    else return "";
  }
}