import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { IOrder, OrderState, IProductOrderItem, OrderSource, OrderErpExportStatus, IShipmentOrderItem, OrderCollectStatus } from '../models/orders.models'
import { IAliasBasicProduct, IMasterBasicOrderProduct } from '../models/products.models';
import { IApiResult } from '../models/api.model';
import { IBaseQuery, IPageResult, ILongRunningOperation } from '../models/common.models';
declare var $: any;
import { CommonService, ConvertObjectToQuery } from './common.service';
import { AuthorizationService } from './authurization.service';
import { ShipmentStatus, ShipmentState } from '../models/shipments.models';
import { SeverityLevelType } from '../models/validation.models';
import { ShipmentService } from './shipment.service';
import { StoreType } from '../models/stores';
import { map, tap } from 'rxjs/operators';
import { AdminService } from './admin.service';
import { ISupplierConfiguration } from '../models/suppliers';
import { promise } from 'protractor';
import { Store } from '@ngrx/store';
import { StatisticsUpdateDraftOrderCountStart, StatisticsUpdateERPErrorOrderCountStart, StatisticsUpdateInvalidShipmentOrderCountStart, StatisticsUpdateOutOfStockOrderCountStart } from '../state/statistics.actions';
import { ConfigStateFeature } from '../state/config.reducer';


@Injectable()
export class OrderService {

  private ordersListUpdater!: Subscription;
  private supplierConfiguration!: ISupplierConfiguration
  constructor(private _commonService: CommonService, private _authService: AuthorizationService, private _http: HttpClient, private _adminService: AdminService, private _shipmentService: ShipmentService, private store: Store) {
    
  }
  private _apiUrl = "/api/Orders"

  /*  public DraftOrderCount: Subject<number> = new Subject<number>();
    public ERPErrorOrderCount: Subject<number> = new Subject<number>();
    public InvalidShipmentOrderCount: Subject<number> = new Subject<number>();
    public OutOfStockOrderCount: Subject<number> = new Subject<number>();*/

  public CreateNewOrderItem(product: IAliasBasicProduct | IMasterBasicOrderProduct): IProductOrderItem {

    return {
      ItemType: "Product",
      ItemPrice: 0,
      ItemVATPrice: 0,
      Product: product,
      Quantity: 1
    };
  }

  public GetOrder(id: string): Observable<IOrder> {
    return this._http.get<IOrder>(this._apiUrl + '/get?OrderId=' + encodeURIComponent(id));
  }

  public SaveOrder(order: IOrder, autoCorecct: boolean = false): Observable<IApiResult<IOrder>> {
    return this._http.put<IApiResult<IOrder>>(this._apiUrl + '/Save?autoCorecct=' + (autoCorecct ? "true" : "false"), order)
      .pipe(
        tap(r => this.store.dispatch(StatisticsUpdateDraftOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateERPErrorOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateInvalidShipmentOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateOutOfStockOrderCountStart())),
      );
  }

  public SearchOrders(query: IOrderSearchRequest): Observable<IApiResult<IPageResult<IOrder>>> {
    return this._http.get<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/Search?' + ConvertObjectToQuery(query));
  }

  public AggregateSearchOrders(query: IOrderSearchRequest, aggregatePipes: IAggregatePipe[]): Observable<IApiResult<IPageResult<any>>> {
    return this._http.post<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/AggregateSearch',
      {
        Query: query,
        Pipes: aggregatePipes
      }
    );
  }



  public ERPSearch(query: IOrderSearchRequest): Observable<IApiResult<IPageResult<IOrder>>> {
    return this._http.get<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/ERPSearch?' + ConvertObjectToQuery(query));
  }

  public GetPathValue(orderId: string, path: string): Observable<IApiResult<IPageResult<IOrder>>> {
    return this._http.get<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/GetPathValue?orderId=' + encodeURIComponent(orderId) + '&path=' + encodeURIComponent(path));
  }

  public RevalidateOrders(query: IOrderSearchRequest): Observable<IApiResult<IPageResult<IOrder>>> {
    return this._http.post<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/RevalidateOrders', this.fixQueryForPost(query))
      .pipe(
        tap(r => this.store.dispatch(StatisticsUpdateDraftOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateInvalidShipmentOrderCountStart()))
      );
    //  return this._http.get<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/RevalidateOrders?' + ConvertObjectToQuery(query));
  }

  public ResetOrderItemPriceToRecommendedPrice(query: IOrderSearchRequest, IncludeOrderItemIds: string[] | null, ExcludeOrderItemIds: string[] | null): Observable<IApiResult<boolean>> {
    return this._http.post<IApiResult<boolean>>(this._apiUrl + '/ResetOrderItemPriceToRecommendedPrice',
      {
        Query: this.fixQueryForPost(query),
        IncludeOrderItemIds: IncludeOrderItemIds,
        ExcludeOrderItemIds: ExcludeOrderItemIds
      }).pipe(tap(r => this.store.dispatch(StatisticsUpdateDraftOrderCountStart())));

  }

  public SetState(query: IOrderSearchRequest, state: OrderState): Observable<IApiResult<IPageResult<number>>> {
    return this._http.post<IApiResult<IPageResult<number>>>(this._apiUrl + '/SetState', { Query: this.fixQueryForPost(query), State: state })
      .pipe(
        tap(r => this.store.dispatch(StatisticsUpdateDraftOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateERPErrorOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateOutOfStockOrderCountStart())),
      );
  }

  public SetErpStatus(orderQuery: IOrderSearchRequest, erpStatus: OrderErpExportStatus): Observable<IApiResult<number>> {
    return this._http.post<IApiResult<number>>(this._apiUrl + '/SetErpStatus', { Query: orderQuery, Status: erpStatus })
      .pipe(tap(r => this.store.dispatch(StatisticsUpdateERPErrorOrderCountStart())));
  }



  public UnifyOrderShipments(leadingShipmentId: string, FollowingShipmentIds: string[]): Observable<IApiResult<IPageResult<IOrder>>> {
    return this._http.post<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/UnifyShipments', { LeadingShipmentId: leadingShipmentId, FollowingShipmentIds: FollowingShipmentIds })
      .pipe(
        tap(r => this.store.dispatch(StatisticsUpdateDraftOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateERPErrorOrderCountStart())));
  }


  public UnUnifyOrderShipments(preUnifyShipmentIds: string[]): Observable<IApiResult<IPageResult<IOrder>>> {
    return this._http.post<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/UnUnifyShipments', { PreUnifyShipmentIds: preUnifyShipmentIds })
      .pipe(
        tap(r => this.store.dispatch(StatisticsUpdateDraftOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateERPErrorOrderCountStart())));
  }


  public SetCollectStatus(orderQuery: IOrderSearchRequest, status: OrderCollectStatus): Observable<IApiResult<number>> {
    return this._http.post<IApiResult<number>>(this._apiUrl + '/SetCollectStatus', { Query: orderQuery, Status: status });
  }

  public SetShipmentStatus(query: IOrderSearchRequest, status: ShipmentStatus): Observable<IApiResult<boolean>> {
    return this._http.post<IApiResult<boolean>>(this._apiUrl + '/SetShipmentStatus', { Query: this.fixQueryForPost(query), Status: status });
  }
  public DeleteOrders(query: IOrderSearchRequest, reason: string): Observable<IApiResult<IPageResult<IOrder>>> {
    return this._http.post<IApiResult<IPageResult<IOrder>>>(this._apiUrl + '/Delete?' + ConvertObjectToQuery({ Reason: reason }), this.fixQueryForPost(query))
      .pipe(
        tap(r => this.store.dispatch(StatisticsUpdateDraftOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateERPErrorOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateOutOfStockOrderCountStart())));
  }

  public ImportOrdersFromFiles(uploadFileIds: string[]): Observable<IApiResult<ILongRunningOperation>> {
    return this._http.post<any>(this._apiUrl + '/ImportOrdersFromFiles', uploadFileIds)
      .pipe(
        tap(r => this.store.dispatch(StatisticsUpdateDraftOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateERPErrorOrderCountStart())),
        tap(r => this.store.dispatch(StatisticsUpdateOutOfStockOrderCountStart())));
  }

  public PrintOrders(type: string, query: IOrderSearchRequest): Observable<IApiResult<ILongRunningOperation>> {
    return this._http.post<any>(this._apiUrl + '/Print/' + type, this.fixQueryForPost(query));
  }

  private fixQueryForPost(query: IOrderSearchRequest): IOrderSearchRequest {

    var result = $.extend(true, {}, query);

    function fixEnumArray<EnumType>(field: string | string[] | EnumType | EnumType[], enummap: any): EnumType[] {

      if (typeof field === "string") field = [field];
      else if (typeof field === "number") field = [field];
      field = field as string[] | EnumType[];

      for (let index = 0; index < field.length; index++) {
        const element = field[index];
        if (typeof element == "string") field[index] = enummap[element] as EnumType;
      }

      return field as EnumType[];
    }

    if (result.OriginStore) result.OriginStore = fixEnumArray<StoreType>(result.OriginStore, StoreType);
    if (result.ShipmentStatus) result.ShipmentStatus = fixEnumArray<ShipmentStatus>(result.ShipmentStatus, ShipmentStatus);
    if (result.OrderState) result.OrderState = fixEnumArray<OrderState>(result.OrderState, OrderState);

    return result;
  }
  public CreateNewOrder(): IOrder {
    return {
      Id: null,
      Items: [],
      SupplierNumber: "",
      Shipments: [this._shipmentService.CreateNewShipment()],
      State: OrderState.Active,
      ERPExportStatus: OrderErpExportStatus.Ready,
      CollectStatus: OrderCollectStatus.Ready,
      Shopper: {
        FullName: "",
        Email: "",
        Phone: ""
      },
      //   Audit: this._commonService.CreateNewItemAuditTrace(),
      //   SupplierId: (this._authService.LoggedUser) ? this._authService.LoggedUser.SupplierId : "",
      DeductedFromStock: false,
      FailedValidations: [],
      Source: {
        OriginStore: StoreType.Manual,
        Type: OrderSource.Manual
      }
    }
  }



 

}


export interface IOrderSearchRequest extends IBaseQuery {
  SupplierNumber?: string,
  SupplierNumberIn?: string[],
  SupplierNumberContains?: string,
  OrderState?: OrderState[],
  ERPExportStatus?: OrderErpExportStatus[],
  CollectStatus?: OrderCollectStatus[],
  NotOrderState?: OrderState[],
  ShipmentIds?: string[],
  PreUnifyShipmentIds?: string[],
  ShipmentState?: ShipmentState[],
  NotShipmentState?: ShipmentState[],
  ShipmentStatus?: ShipmentStatus[],
  ShipmentType?: string[],
  OriginStore?: StoreType[],
  SKU?: string,
  ShopperName?: string,
  CreatedDateFrom?: Date | string,
  CreatedDateTo?: Date | string,
  OperationTimeFrom?: Date | string,
  OperationTimeTo?: Date | string,
  AgeSeconds?: number,
  OperationAgeSeconds?: number,
  SourceOperationID?: string,
  SeverityFrom?: SeverityLevelType,
  SeverityTo?: SeverityLevelType,
  FailedValidationsType?: number[],
  OperationID?: string,
  FreeSearch?: string,
  ShipmentTrackingNumber?: string,
  HasShipmentTrackingNumber?: boolean,
  Phone?: string,
  ShopperFullName?: string,
  RemarksContain?: string,
  HasUnifyShipment?: boolean
}

export interface IAggregatePipe {
  ItemType: string
}

export interface IAggregateGroupPipe extends IAggregatePipe {
  AggregateFields?: { [key: string]: IAggregateGroupBaseField }
  GroupByFields: { [key: string]: string }
}

export interface IAggregateGroupBaseField {
  ItemType: string
}

export class AggregateMaxField implements IAggregateGroupBaseField {
  ItemType: string = "AggregateMaxField";
  public Field: string;
  constructor(field: string) {
    this.Field = field;
  }
}

export class AggregateMinField implements IAggregateGroupBaseField {
  ItemType: string = "AggregateMinField";
  public Field: string;
  constructor(field: string) {
    this.Field = field;
  }
}




export interface IAggregateFilterPipe extends IAggregatePipe {
  Filter: any
}



export function GetShipmentItemFromOrder(order: IOrder): IShipmentOrderItem[] | null {
  return order.Items.filter(item => item.ItemType === "Shipment") as IShipmentOrderItem[];
}

export function GetProductItemsFromOrder(order: IOrder): IProductOrderItem[] {
  return order.Items.filter(item => item.ItemType === "Product") as IProductOrderItem[];
}