import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderService, IOrderSearchRequest as IOrderSearchRequest } from 'src/app/services/order.service';
import { ActivatedRoute, DefaultUrlSerializer, Router, UrlSerializer } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { IPageResult } from 'src/app/models/common.models';
import { IOrder } from 'src/app/models/orders.models';
import { ShipmentState, IShipment, DropPointableShipmentTypes, GetDropPointType } from 'src/app/models/shipments.models';
declare var $: any;
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  templateUrl: './shipment-submitter.page.component.html',
  styleUrls: ['./shipment-submitter.page.component.css'],
  animations: [
    trigger('submitEffect', [
      state("initial", style({
        backgroundColor: 'white'
      })),
      state('error', style({
        backgroundColor: 'red'
      })),
      state('success', style({
        backgroundColor: 'green'
      })),
      transition("initial=>error", animate('50ms')),
      transition("initial=>success", animate('50ms')),
      transition("error=>initial", animate('1000ms')),
      transition("success=>initial", animate('1000ms')),
    ])
  ]
})
export class ShipmentSubmitterPageComponent implements OnInit {

  constructor(
    private _orderService: OrderService,
    private _shipmnetService: ShipmentService,
    private _route: ActivatedRoute,
    private CdRef: ChangeDetectorRef,
    private _router: Router,
    private _urlSerializer: UrlSerializer
  ) { }

  public Query: IOrderSearchRequest;
  private returnUrl: string;
  public ReturnDescription: string;
  public Orders!: IPageResult<IOrder>;
  public OrderShipmentErrorsCount!: number;
  public EditShipments: { [key: string]: IShipment | null } = {}
  public SavingShipments: { [key: string]: IShipment | null } = {}
  public AnimatedShipmentsState: { [key: string]: string | null } = {}
  public HasDropPointShipmentsType: boolean = false
  public DidComeFromPrintPage: boolean = false;
  ngOnInit() {
    this._route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.returnUrl = params.returnUrl;

        this.DidComeFromPrintPage = /\/print-orders\?/.test(this.returnUrl);
        this.ReturnDescription = params.returnDescription;
        this.Query = JSON.parse(params.query) as IOrderSearchRequest;
        this.Query.PageSize = 50;
        this.LoadOrders();
        this.refreshErrorCounter();
      });

  }

  public LoadOrders() {
    var realQuery = $.extend({}, this.Query);
    realQuery.ProjectionFields = ["Id", "SupplierNumber", "Shipments"];
    this._orderService.SearchOrders(realQuery).subscribe(result => {
      if (result.Code === 0) {
        this.Orders = result.Result;
        this.refreashHasDropPointShipmentsType();
      }
    });
  }

  private refreashHasDropPointShipmentsType() {
    for (let index = 0; index < this.Orders.Items.length; index++) {
      const order = this.Orders.Items[index];
      if (DropPointableShipmentTypes.indexOf(order.Shipments[0].ItemType) >= 0) {
        this.HasDropPointShipmentsType = true;
        return;
      }
    }
    this.HasDropPointShipmentsType = false;
  }

  private refreshErrorCounter() {
    var errorCounterQuery = $.extend({}, this.Query);
    errorCounterQuery.ShipmentState = [ShipmentState.SubmitError];
    errorCounterQuery.PageSize = 0;

    this._orderService.SearchOrders(errorCounterQuery).subscribe(result => {
      if (result.Code === 0) {
        this.OrderShipmentErrorsCount = result.Result.TotalItemsCount;
      }
    });
  }
  public EditShipmnet(ship: IShipment) {
    this.EditShipments[ship.Id] = $.extend({}, ship);
    this.CdRef.detectChanges();
  }

  public DropPointType(ship: IShipment): string {
    return GetDropPointType(ship.ItemType);
  }

  public SaveShipment(ship: IShipment) {
    this.SavingShipments[ship.Id] = ship;

    this._shipmnetService.SubmitShipment(ship).subscribe(r => {
      if (r.Code === 0) {
        var orderItem = this.Orders.Items.find(o => o.Shipments.findIndex(s => s.Id == ship.Id) >= 0);
        orderItem.Shipments[orderItem.Shipments.findIndex(s => s.Id == ship.Id)] = r.Result;
        this.AnimatedShipmentsState[ship.Id] = r.Result.ErrorMessage ? "error" : "success";
      }
      delete this.EditShipments[ship.Id];
      delete this.SavingShipments[ship.Id];

      this.refreshErrorCounter();
      this.CdRef.detectChanges();
    });
  }

  public GetShipmentSubmitState(ship: IShipment): string {
    return this.AnimatedShipmentsState[ship.Id] || 'initial';
  }
  public ClearShipmentSubmitState(ship: IShipment) {
    delete this.AnimatedShipmentsState[ship.Id];
  }
  public IsInEditMode(ship: IShipment): boolean {
    return !!this.EditShipments[ship.Id];
  }

  public NavigateToPrintValidOnlyUrl(): void {

    var url = this._urlSerializer.parse(this.returnUrl);
    var query = JSON.parse(url.queryParams.query) as IOrderSearchRequest;
    
    query.NotShipmentState = (query?.NotShipmentState ?? []);
    query.NotShipmentState.push(ShipmentState.SubmitError);
    
    url.queryParams.query = JSON.stringify(query);
    this._router.navigateByUrl(url);
  }

  public NavigateToReturnUrl(): void {
    this._router.navigateByUrl(this.returnUrl);
  }
}


