import { IDBObject } from './common.models'


export interface IExternalFile extends IDBObject {
  FileName: string | null,
  Length: number | null
  Path: string | null,
  Type: FileType;
}


export enum FileType {
  UnKnown = 0, UnKnownDescription = <any>"לא ידוע",
  Excel = 1 << 0, ExcelDescription = <any>"אקסל",
  CSV = 1 << 1, CSVDescription = <any>" CSV",

  PDF = 1 << 2, PDFDescription = <any>" PDF",
  HTML = 1 << 3, HTMLDescription = <any>" HTML",
  Text = 1 << 4, TextDescription = <any>"טקסט",
  TSV = 1 << 5, TSVDescription = <any>" TSV",
  XML = 1 << 6, XMLDescription = <any>" XML",
  Import = 1 << 10, ImportDescription = <any>"",
  Export = 1 << 11, ExportDescription = <any>"",
  Print = 1 << 12, PrintDescription = <any>"",

  SchemaOriginDistributer = 1 << 13, SchemaOriginDistributerDescription = <any>"",
  SchemaOriginRetail = 1 << 14, SchemaOriginRetailDescription = <any>"",
  QuoteAllFields = 1 << 19,

  Operational = 1 << 20, OperationalDescription = <any>"",

  QuoteCSV = CSV | QuoteAllFields, QuoteCSVDescription = <any>"  עם גרשיים CSV",
}



export interface IDataTable<Type> {
  Name: string,
  ColumnsNames: string[]
  Rows: Type[][]
}