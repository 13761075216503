import { IAddress, IIdentity, IDBObject, IGeoCoordinate } from './common.models'


export enum ShipmentStatus {
  New = 1000, NewDescription = <any>"חדש",
  Printed = 2000, PrintedDescription = <any>"הודפס",
  Packed = 3000, PackedDescription = <any>"נארז",
  WaitingForPickup = 3500, WaitingForPickupDescription = <any>"ממתין לאיסוף עצמי",
  Sent = 4000, SentDescription = <any>"נשלח\\נמסר",
  Reported = 5000, ReportedDescription = <any>"דווח",
  Received = 9000, ReceivedDescription = <any>"התקבל",
  ReturnPrinted = 12000, ReturnPrintedDescription = <any>"מדבקת החזרה הודפסה",
  Returned = 19000, ReturnedDescription = <any>"חזר",
  Unified = 100000, UnifiedDescription = <any>"מאוחד"
}

export var UndefinedShipmentType = "UndefinedShipment";
export var DeliveryShipmentsTypes = [
  "DeliveryShipment",
  "ChitaDeliveryShipment",
  "PaxxiDeliveryShipment",
  "BaldarDeliveryShipment",
  "ChitaShopsShipment",
  "YDMDeliveryShipment",
  "YDMShopsShipment",
  "OrionDeliveryShipment",
  "OrionShopsShipment",
  "SosnaDeliveryShipment",
  "SosnaShopsShipment",
  "HFDDeliveryShipment",
  "EPostShipment",
  "POShipment",
  "Manual1DeliveryShipment",
  "Manual2DeliveryShipment",
  "Manual3DeliveryShipment",
  "Manual4DeliveryShipment",
  "Manual5DeliveryShipment",
  "Manual6DeliveryShipment",
  "Manual7DeliveryShipment",
  "Manual8DeliveryShipment",
  "Manual9DeliveryShipment",
  "Manual10DeliveryShipment",
  "Manual11DeliveryShipment",
  "Manual12DeliveryShipment",
  "Manual13DeliveryShipment",
  "Manual14DeliveryShipment",
  "Manual15DeliveryShipment",
  "WallashopsDistributorShipment",
  "AzrieliDistributorShipment",
  "GrooDistributorShipment",
  "RegisterPOShipment",
  "FedExShipment",
  "CargoDeliveryShipment",
  "BoxItShipment",
  "UPSDeliveryShipment",
  "UPSPickUpShipment",
  UndefinedShipmentType];

export var IgnoreAddressValidationShipmentTypes = ["WallashopsDistributorShipment", "AzrieliDistributorShipment", "GrooDistributorShipment"];
export var DropPointableShipmentTypes = ["BoxItShipment", "ChitaShopsShipment", "YDMShopsShipment", "SosnaShopsShipment", "UPSPickUpShipment", "EPostShipment", "OrionShopsShipment",];
export var ExternalSubmittableShipmentTypes = DropPointableShipmentTypes.concat([
  "ChitaDeliveryShipment",
  "PaxxiDeliveryShipment",
  "BaldarDeliveryShipment",
  "YDMDeliveryShipment",
  "OrionDeliveryShipment",
  "SosnaDeliveryShipment",
  "HFDDeliveryShipment",
  "EPostShipment",
  "POShipment",
  "WallashopsDistributorShipment",
  "AzrieliDistributorShipment",
  "GrooDistributorShipment",
  "RegisterPOShipment",
  "FedExShipment",
  "CargoDeliveryShipment",
  "BoxItShipment",
  "UPSDeliveryShipment",
  "UPSPickUpShipment"
]);


export function GetDropPointType(ShipmentItemType: string): string {
  if (ShipmentItemType == "BoxItShipment") return "BoxitDropPoint";
  else if (ShipmentItemType == "UPSPickUpShipment") return "UPSDropPoint";
  else if (ShipmentItemType == "YDMShopsShipment") return "YDMDropPoint";
  else if (ShipmentItemType == "OrionShopsShipment") return "OrionDropPoint";
  else if (ShipmentItemType == "SosnaShopsShipment") return "SosnaDropPoint";
  else if (ShipmentItemType == "EPostShipment") return "EPostDropPoint";
  else return "ChitaDropPoint";
}

export enum ShipmentState {
  Active = 1 << 0, ActiveDescription = <any>"פעיל",
  SubmitError = 1 << 1, SubmitErrorDescription = <any>"שגיאת שידור",
  IgnoreValidation = 1 << 2, IgnoreValidationDescription = <any>"כתובת שגויה",
  Suspended = 1 << 3, SuspendedDescription = <any>"מושהה",
  Canceled = 1 << 6, CanceledDescription = <any>"מבוטל",
}

export interface IShipment extends IDBObject {
  Id: string | null,
  OrderId: string | null,
  ItemType: string,
  State: ShipmentState,
  Status: ShipmentStatus,
  Recipient: IIdentity,
  Remarks?: string,
  ComputedRemarks?: string,
  TrackingNumber?: string,
  PackagesCount: number,
  ErrorMessage?: string | null,
  PreUnifyShipment?: IShipment | null,
  UnifyShipmentIds?: string[] | null,
  IsUnify?: boolean
}

export interface IDropPointShipment {
  DropPoint: IDropPoint,
  DropPointCode: string
}

export interface IDeliveryShipment extends IShipment {
  Address: IAddress
}

export interface IUndefinedShipment extends IDeliveryShipment, IDropPointShipment {
  SourceTypeValue: string
}

export interface IBoxItShipment extends IDeliveryShipment, IDropPointShipment {
  DropPoint: IBoxitDropPoint | null
}

export interface IChitaShopsShipment extends IDeliveryShipment, IDropPointShipment {
  DropPoint: IChitaDropPoint | null
}

export interface IYDMShopsShipment extends IDeliveryShipment, IDropPointShipment {
  DropPoint: IYDMDropPoint | null
}

export interface ISosnaShopsShipment extends IDeliveryShipment, IDropPointShipment {
  DropPoint: ISosnaDropPoint | null
}

export interface IEPostShipment extends IDeliveryShipment, IDropPointShipment {
  DropPoint: ISosnaDropPoint | null
}

export interface IUPSPickUpShipment extends IDeliveryShipment, IDropPointShipment {
  DropPoint: IUPSDropPoint | null
}

export interface IOrionShopsShipment extends IDeliveryShipment, IDropPointShipment {
  DropPoint: IOrionDropPoint | null
}

export interface IShipmentType {
  Id: string,
  Description: string,
  IsDeliveryShipment: boolean,
  IsSelfDistributer: boolean,
  LogoURL: string
}
export interface IShipmentTypes { [key: string]: IShipmentType };


export interface IDropPoint {
  Name: string,
  Code: string,
  Address?: IAddress,
  GeoCoordinate?: IGeoCoordinate,
  ItemType: string,
}


export enum BoxitLockerTypes {
  Locker = 1,
  Shop = 3
}
export interface IBoxitDropPoint extends IDropPoint {

  LockerType: BoxitLockerTypes,
  IsActive: boolean,
  Comments: string,
  ShopPhone: string,
  OpeningHours: string

}

export enum SpotTypes {
  None = 1,
  Locker = 2,
  Shop = 3
}

export interface IChitaDropPoint extends IDropPoint {
  SpotType: SpotTypes,
  Remarks: string,
  Line: number,
  Line_desc: string,
  Distribution_area: number,
  Distribution_area_desc: string,
  Ahzarot: boolean,
  Negishut_lebaaley_mugbaluyot: boolean
}


export interface IYDMDropPoint extends IDropPoint {
  SpotType: SpotTypes,
  Remarks: string,
  Line: number,
  Line_desc: string,
  Distribution_area: number,
  Distribution_area_desc: string,
  Ahzarot: boolean,
  Negishut_lebaaley_mugbaluyot: boolean
}


export interface ISosnaDropPoint extends IDropPoint {
  SpotType: SpotTypes,
  Remarks: string,
  Line: number,
  Line_desc: string,
  Distribution_area: number,
  Distribution_area_desc: string,
  Ahzarot: boolean,
  Negishut_lebaaley_mugbaluyot: boolean
}

export enum UPSDropPointTypes {
  Store = 1 << 0,
  Locker = 1 << 1,
  StoreAndLocker = Store | Locker,
  Drop = 1 << 2,
}

export interface IUPSDropPoint extends IDropPoint {
  DropPointId: string,
  IsActive: boolean,
  Comments: string,
  DropPointType: UPSDropPointTypes
}


export interface IOrionDropPoint extends IDropPoint {
  FullAddress: string,
  PUDOID: string
}