import { FileType, IExternalFile } from "./files.models";
import { IAddress, IDBObject, IIdentity } from "./common.models";


export interface ISupplierUser extends IDBObject {
  UserIdentity: IIdentity,
  UserName: string,
  Roles: string[],
  Disable: boolean
}

export var SupplierUserRolesTypes = {
  "Admin": "מנהל מערכת",
  "OrdersManager": "ניהול הזמנות",
  "OrdersOperator": "תפעול הזמנות",
  "ERP_System": "סוכנת ERP"
}

export interface ISupplierConfiguration {
  Stores: {
    [key: string]: ISupplierStoreConfiguration
  },
  Shipments: {
    [key: string]: ISupplierShipmentConfiguration
  },
  Exports?: ISupplierExportConfiguration[],
  LocalERPConfiguration?: ISupplierLocalERPConfiguration
  VatRate: number,
  RetentionPolicy: IRetentionPolicyConfiguration
  StockSetting: IStockConfiguration,
  ProductSetting: IProductConfiguration,
  UIConfiguration?: IUIConfiguration;
  
}

export interface IStockConfiguration {
  Enable: boolean,
  MinimumStockLevel: number,
}



export var AllProductImportFileTypes = [
  { id: "Operational", name: " סטנדרטי של SendE" },
  { id: "Miracle", name: "מירקל" },
  { id: "Tafnit", name: "תפנית" },
];


export interface IProductConfiguration {
  //PullSourceType?: ProductImportFileTypes,
  PullSourceFileType?: string,
  PullSource?: IStockFTPConfiguration,
  PullPostProcessCopy?: IStockFTPConfiguration,
  ImportProductFormats?: IImportProductFormat[],
}

export interface IUIConfiguration {
  OrderPageQuickButton?: string
}
export interface IStockFTPConfiguration {
  Url?: string | null,
  User?: string | null,
  Password?: string | null,
}

export interface IImportProductFormat {
  Id: string | null,
  Name: string,
  TableReadOption: TableReadOptions,
  ProductImportFieldMapping: IProductImportMappingDefinition[]
}

export enum TableReadOptions {
  ColLetter = 1 << 0, ColLetterDescription = <any>"לפי אות עמודה",
  SingleHeader = 1 << 1, SingleHeaderDescription = <any>"שורת כותרת אחת",
  DoubleHeader = 1 << 2, DoubleHeaderDescription = <any>"שורת כותרת כפולה",
}

export interface IProductImportMappingDefinition {
  SourceColumn: string,
  DestinationProductField: string,
}
export interface IRetentionPolicyConfiguration {
  ShoppersPIIDataExpirationEnabled: boolean,
  ShoppersPIIDataExpirationDays: number,
  OrderAuditEnabled : boolean,
}

export interface ISupplierStoreConfiguration {
  Enabled: boolean,
  ImportConfiguration: ISupplierStoreImportConfiguration,
  ExportConfiguration?: ISupplierStoreExportConfiguration,
  RenameSalfDistributerDeliveryMethod: string,
  ERPNumber: string,
  OrderCustomFieldAliases?: {
    [key: string]: ISupplierStoreConfigurationOrderCustomFieldAlias
  },
  ItemType: string,
  AliasName?: string,
}

export interface ISupplierStoreConfigurationOrderCustomFieldAlias {
  Name: string,
  DefaultValue: string
}

export interface ISupplierStoreImportConfiguration {

  OverrideDefaultFields?: {
    [key: string]: string[]
  },
  IsSalfDistributer: boolean,
  ItemType: string,
  AdditionProductIdentifiersFields?: string[] | null,
  ShippingMethodDefaults: {
    [key: string]: {
      MatchString: string,
      MatchShipPrice?: number
    }[]
  },
  IgnoreOrderRemarks: boolean,
  IgnoreShipmentRemarks: boolean,
  PriceListCeilingWarningPercent?: number,
  PriceListFloorWarningPercent?: number,
  IgnorePriceListWarnings?: boolean,
  OverridePriceList?: boolean,
  IgnoreExistingSupplierNumber?: boolean,
  IgnoreSimilarOrderLineGrouping?: boolean,
  ShipmentStrategies?: ShipmentMethodFindStrategyType[],
  AliasProductMatchingStrategy?: AliasProductMatchingStrategyType
  ShipingPriceStrategy?: ShippingPriceStrategyType
  MultiplyShipmentItemsStrategy: MultiplyShipmentItemsStrategyType
  WarnOnZeroPriceOrderItem: boolean
}


export interface ISupplierStoreExportConfiguration {
  ItemType: string,
  ProductTemplates?: ISupplierCustomExportFileTemplateConfiguration[]
}


export interface ISupplierShipmentConfiguration {
  Enabled: boolean,
  ExportConfiguration: ISupplierShipmentExportConfiguration,
  ImportConfiguration: ISupplierShipmentImportConfiguration,
  ItemType: string,
  AutoIgnoreErrors: boolean,
  AutoTrackingNumber?: boolean
}

export interface ISupplierShipmentExportConfiguration {
  EnableAddionLineInERPExport: boolean,
  ComputedRemarksTemplate?: string | null,
  IgnoreOrderRemarks: boolean,
  IgnoreShipmentRemarks: boolean,
}

export interface ISupplierShipmentImportConfiguration {

  SKU: string,
  Description: string,
  DefaultPrice: number
}
export interface ISupplierLocalERPConfiguration {
  Type: ERPType,
  DatabaseType: ERPDBType,
  Database: string,
  Token : string,
  Server: string,
  Username: string,
  Password: string,
  CustomerId: string,
  Active: boolean,
  IncludeShipmentItems: boolean,
  FieldMapping: { [key: string]: string },
  Param01?: string,
  Param02?: string,
  Param03?: string,
  Param04?: string,
  Param05?: string,
  Param06?: string,
  Param07?: string,
  Param08?: string,
  Param09?: string,
  Param10?: string
}

export enum ERPType {
  SBOOnPrem = 0, SBOOnPremDescription = <any>"SBO בהתקנה מקומית",
  HashavshevetOnPrem = 1, HashavshevetOnPremDescription = <any>"חשבשבת בהתקנה מקומית",
  HashavshevetCloud = 2, HashavshevetCloudDescription = <any>"חשבשבת בענן",
  PriorityOnPrem = 3, PriorityOnPremDescription = <any>"פריוריטי בהתקנה מקומית",
  PriorityCloud = 4, PriorityCloudDescription = <any>"פריוריטי בענן",
  Comax = 5, ComaxDescription = <any>"Comax",
  HConnect = 6, HConnectDescription = <any>"חשבשבת מקומי (HConnect)",
}


export enum ERPDBType {

  NotDefined = 0, NotDefinedDescription = <any>"לא מוגדר",
  MSSQL = 1, MSSQLDescription = <any>"Microsoft SQL Server",
  DB_2 = 2, DB_2Description = <any>"IBM DB2",
  SYBASE = 3, SYBASEDescription = <any>"SAP SYBASE",
  MSSQL2005 = 4, MSSQL2005Description = <any>"Microsoft SQL Server 2005",
  MAXDB = 5, MAXDBDescription = <any>"SAP MAXDB",
  MSSQL2008 = 6, MSSQL2008Description = <any>"Microsoft SQL Server 2008",
  MSSQL2012 = 7, MSSQL2012Description = <any>"Microsoft SQL Server 2012",
  MSSQL2014 = 8, MSSQL2014Description = <any>"Microsoft SQL Server 2014",
  HANADB = 9, HANADBDescription = <any>"SAP HANADB",
  MSSQL2016 = 10, MSSQL2016Description = <any>"Microsoft SQL Server 2016",
  MSSQL2017 = 11, MSSQL2017Description = <any>"Microsoft SQL Server 2017",
  MSSQL2019 = 15, MSSQL2019Description = <any>"Microsoft SQL Server 2019",
}

export interface ISupplierBoxitShipmentConfiguration extends ISupplierShipmentConfiguration {

  UserName: string,
  Password: string,
  CustomerID: string,
  LocationSearchDistance: number
}

export interface ISupplierFedExShipmentConfiguration extends ISupplierShipmentConfiguration {

  UserName: string,
  Password: string,
  CustomerID: string
}

export interface ISupplierCargoDeliveryShipmentConfiguration extends ISupplierShipmentConfiguration {

  /*  UserName: string,
    Password: string, */
  CustomerID: string,
  CustomerName: string
}
export interface ISupplierChitaDeliveryShipmentConfiguration extends ISupplierShipmentConfiguration {

  CustomerID: string,
  AuthenticationToken: string
}

export interface ISupplierChitaShopsShipmentConfiguration extends ISupplierShipmentConfiguration {

  CustomerID: string,
  AuthenticationToken: string
}

export interface ISupplierYDMDeliveryShipmentConfiguration extends ISupplierShipmentConfiguration {

  CustomerID: string,
  AuthenticationToken: string
}

export interface ISupplierYDMShopsShipmentConfiguration extends ISupplierShipmentConfiguration {

  CustomerID: string,
  AuthenticationToken: string
}

export interface ISupplierOrionDeliveryShipmentConfiguration extends ISupplierShipmentConfiguration {

  UserName: string,
  Password: string,
  CustomerID: string,
  PickupAddress: IAddress,
  PickupContact: IIdentity
}

export interface ISupplierOrionShopsShipmentConfiguration extends ISupplierShipmentConfiguration {

  UserName: string,
  Password: string,
  CustomerID: string,
  PickupAddress: IAddress,
  PickupContact: IIdentity
}


export interface ISupplierSosnaDeliveryShipmentConfiguration extends ISupplierShipmentConfiguration {

  CustomerID: string,
  AuthenticationToken: string
}
export interface ISupplierSosnaShopsShipmentConfiguration extends ISupplierShipmentConfiguration {
  CustomerID: string,
  AuthenticationToken: string
}

export interface ISupplierEPostShipmentConfiguration extends ISupplierShipmentConfiguration {
  CustomerID: string,
  AuthenticationToken: string
}

export interface ISupplierHFDDeliveryShipmentConfiguration extends ISupplierShipmentConfiguration {

  CustomerID: string,
  AuthenticationToken: string
}

export interface ISupplierManualShipmentConfiguration extends ISupplierShipmentConfiguration {

  AliasName: string
  AutoTrackingNumber: boolean,
}

export interface ISupplierPickupShipmentConfiguration extends ISupplierShipmentConfiguration {
  PickupAddress: IAddress | null,
  AliasName: string
  AutoTrackingNumber: boolean,
}

export interface ISupplierPaxxiShipmentConfiguration extends ISupplierShipmentConfiguration {
}

export interface ISupplierBaldariShipmentConfiguration extends ISupplierShipmentConfiguration {
}

export interface ISupplierExportConfiguration {
  Name: string,
  ItemType: string
}

export interface ISupplierUPSShipmentConfiguration extends ISupplierShipmentConfiguration {

  UserName: string,
  Password: string,
  CustomerID: string
}
export interface ISupplierUPSPickupShipmentConfiguration extends ISupplierShipmentConfiguration {

  UserName: string,
  Password: string,
  CustomerID: string
}

export interface ISupplierCustomExportFileTemplateConfiguration extends ISupplierExportConfiguration {
  Template: IExternalFile,
  FileType: FileType,
  FileName: string
}



export enum ShipmentMethodFindStrategyType {
  MasterProduct = 1 << 0, MasterProductDescription = <any>"ברירת המחדל במוצר שבהזמנה",
  ShopDefault = 1 << 1, ShopDefaultDescription = <any>"מיפוי שיטת משלוח לחנות",
  ShipmentOrderItemSKU = 1 << 2, ShipmentOrderItemSKUDescription = <any>"התאמת SKU של שורת משלוח להגדרת ערוץ הפצה",
}


export enum AliasProductMatchingStrategyType {
  BySKU = 1 << 0, BySKUDescription = <any>"לפי מק\"ט בלבד",
  ByUPC = 1 << 1, ByUPCDescription = <any>"לפי מספר יצרן בלבד",
  ByName = 1 << 2, ByNameDescription = <any>"לפי שם מוצר בלבד",
  BySKUAndName = 1 << 3, BySKUAndNameDescription = <any>"לפי שילוב של מק\"ט ושם מוצר",
  ByUPCAndName = 1 << 4, ByUPCAndNameDescription = <any>"לפי שילוב של מספר יצרן ושם מוצר",
  ByModelProductIdentifiers = 1 << 5, ByModelProductIdentifiersDescription = <any>"לפי התאמה של מודל מול כול המזהים האפשריים",
  ByAnyProductIdentifierstIdentifiers = 1 << 6, ByAnyProductIdentifierstIdentifiersDescription = <any>"לפי התאמה של כול המזהים האפשריים ללא תלות בסוג",
  ByIdentifierAndName = BySKUAndName | ByUPCAndName, ByIdentifierAndNameDescription = <any>"לפי שילוב של מזהה ושם מוצר",
  Default = BySKU | ByUPC | ByName | ByModelProductIdentifiers | ByAnyProductIdentifierstIdentifiers, DefaultDescription = <any>"ברירת מחדל",
}


export enum ShippingPriceStrategyType {
  IfZeroOverrideByShipmentSetting = 1 << 0, IfZeroOverrideByShipmentSettingDescription = <any>"לפי המחיר בקובץ ההזמנות אחרת ערוץ הפצה",
  OverrideByShipmentSetting = 1 << 1, OverrideByShipmentSettingDescription = <any>"לפי המחיר בערוץ ההפצה",
  SourceOnlySetting = 1 << 2, SourceOnlySettingDescription = <any>"לפי המחיר שמתקבל בקובץ ההזמנות בלבד",
}


export enum MultiplyShipmentItemsStrategyType {
  Keep = 0, KeepDescription = <any>"כול המשלוחים",
  Max = 1 << 0, MaxDescription = <any>"המשלוח היקר ביותר",
  Average = 1 << 1, AverageDescription = <any>"ממוצע לפי מחיר",
  First = 1 << 2, FirstDescription = <any>"משלוח ראשון",
  Last = 1 << 3, LastDescription = <any>"משלוח אחרון"
}


export interface ISupplierMainSetting {
  Name: string;
}