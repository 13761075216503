import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

declare var $: any;

import { AggregateMaxField, AggregateMinField, IAggregateFilterPipe, IAggregateGroupPipe, IOrderSearchRequest, OrderService } from 'src/app/services/order.service';
import { IShipment, ShipmentStatus } from 'src/app/models/shipments.models';
import { IOrder, IProductOrderItem, OrderSource, OrderState } from 'src/app/models/orders.models';
import { IPageResult } from 'src/app/models/common.models';

import { ShipmentService } from 'src/app/services/shipment.service';
import { DateSelectiontModel } from '../../usercontrols/datetimedropdownpicker/dateropdownpicker.usercontrol.component';
import { ConvertToArrayOfNumbers, IsArrayEqual } from 'src/app/helpers/common.helper';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'src/app/deprecated-open-source-projects/angular-2-dropdown-multiselect/src/public-api';





@Component({
  selector: 'app',
  templateUrl: './order-shipment-merger.page.component.html',
  styleUrls: ['./order-shipment-merger.page.component.css']
})


export class OrderShipmentMergerPageComponent implements OnInit {
  constructor(private _route: ActivatedRoute,
    private _orderService: OrderService,
    private _router: Router,
    private _shipmentService: ShipmentService,
  ) { }

  public OrderShipments: IPageResult<OrderShipmentItemReport>;
  public OrderIdMapping: { [key: string]: string } = {}; // mapping from pre ship ID to the unify ID
  public OrderLeadingIds: string[] = []; // holds all the unify Ids
  public OrderPackageCountChanged: string[] = [];

  public DefaultQuery: IOrderSearchRequest = {
    ShipmentStatus: [ShipmentStatus.New],
    OrderState: [OrderState.Active],
    ProjectionFields: ["Shopper.FullName", "Shipments", "State", "Source.Type", "Source.OriginStore", "SupplierNumber", "Id", "Audit.CreatedAt", "Items.Product.SKU", "Items.Quantity"],
    PageSize: 10,
    PageIndex: 0,
   
  }

  public Query: IOrderSearchRequest = {};

  public FillersSettings = {
    UnifyOptions: <IMultiSelectOption[]>[{ id: filterUnify.unUnifyShipmentOnly, name: "הזמנות לא מאוחדות" }, { id: filterUnify.unifyShipmentOnly, name: "הזמנות מאוחדות" }],
    CheckboxListSetting: <IMultiSelectSettings>{
      enableSearch: false,
      checkedStyle: 'fontawesome',
      buttonClasses: 'btn btn-primary btn-sm',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: true,
      pullRight: false,
      closeOnSelect: true
    },
    GetIMultiSelectTexts: function (Title: string): IMultiSelectTexts {
      return {
        checkAll: 'בחר הכול',
        uncheckAll: 'הסר הכול',
        checked: Title + ' נבחרה',
        checkedPlural: Title + ' נבחרו',
        searchPlaceholder: 'חפש',
        searchEmptyResult: 'לא קיים',
        searchNoRenderText: 'הקלד לחיפוש',
        defaultTitle: Title,
        allSelected: 'כול ' + Title,
      };
    }
  }

  async ngOnInit(): Promise<void> {

    this._route
      .queryParams
      .subscribe(params => {


        var query = $.extend(true, {}, params);

        // Defaults to 0 if no query param provided.
        if (query.ShipmentStatus) query.ShipmentStatus = ConvertToArrayOfNumbers(query.ShipmentStatus);
        if (query.ShipmentState) query.ShipmentState = ConvertToArrayOfNumbers(query.ShipmentState);
        if (query.NotShipmentState) query.NotShipmentState = ConvertToArrayOfNumbers(query.NotShipmentState);
        if (query.HasUnifyShipment) query.HasUnifyShipment = String(query.HasUnifyShipment) == 'true';


        if (query.OrderState) query.OrderState = ConvertToArrayOfNumbers(query.OrderState);
        // else query.NotOrderState = [OrderState.Draft];


        if (query.ShipmentType) {
          if (!Array.isArray(query.ShipmentType)) {
            query.ShipmentType = [query.ShipmentType];
          }
        }

        if (query.ERPExportStatus) query.ERPExportStatus = ConvertToArrayOfNumbers(query.ERPExportStatus);
        if (query.CollectStatus) query.CollectStatus = ConvertToArrayOfNumbers(query.CollectStatus);


        this.loadPage(query);


      });


  }

  public get IsUnifyFilterSelected() {
    return (Object.prototype.hasOwnProperty.call(this.Query, "HasUnifyShipment"));
  }

  public UnifyFilter: filterUnify[] = []


  public UpdateUnifyFilter() {
    if (this.UnifyFilter.length == 1) this.Query.HasUnifyShipment = (this.UnifyFilter[0] == filterUnify.unifyShipmentOnly);
    else delete this.Query.HasUnifyShipment;

    this.reloadPage(true);
  }

  async loadPage(query: { [x: string]: any; OriginStore?: any; }) {

    this.Query = $.extend(true, {}, this.DefaultQuery, query);

    if (Object.prototype.hasOwnProperty.call(this.Query, "HasUnifyShipment")) this.UnifyFilter = [this.Query.HasUnifyShipment ? filterUnify.unifyShipmentOnly : filterUnify.unUnifyShipmentOnly];

    if (this.Query.AgeSeconds) this.Query.AgeSeconds = Number(this.Query.AgeSeconds);
    if (typeof this.Query.OrderByFields === "string") this.Query.OrderByFields = [this.Query.OrderByFields];

    var finalQuery = $.extend(true, {}, this.Query);

    var groupPipe = {
      ItemType: "Group",
      GroupByFields: {
        "City": "Shipments.Address.City",
        "PhoneHash": "Shipments.Recipient.PhoneHash"
      },
      AggregateFields: {
        "MinOrderCreatedAt": new AggregateMinField("Audit.CreatedAt"),
        "MaxOrderCreatedAt": new AggregateMaxField("Audit.CreatedAt"),
      }
    } as IAggregateGroupPipe;


    var postGroupFilterPipe = {

      ItemType: "Filter",
      Filter: {
        "Count": { $gte: 2 },
        "Id.City": { $ne: null, $not: { $size: 0 } },
        "Id.PhoneHash": { $ne: null, $not: { $size: 0 } }
      },
    } as IAggregateFilterPipe;


    if (finalQuery.AgeSeconds) {
      var finalDate = new Date(Number(new Date()) - (finalQuery.AgeSeconds * 1000))
      postGroupFilterPipe.Filter.MaxOrderCreatedAt = { $gte: finalDate };
      delete finalQuery.AgeSeconds;
    }
    if (finalQuery.CreatedDateFrom) {
      postGroupFilterPipe.Filter.MaxOrderCreatedAt = { $gte: finalQuery.CreatedDateFrom };
      delete finalQuery.CreatedDateFrom;
    }
    if (finalQuery.CreatedDateTo) {
      postGroupFilterPipe.Filter.MaxOrderCreatedAt = { $lte: finalQuery.CreatedDateTo };
      delete finalQuery.CreatedDateTo;
    }

    finalQuery.ProjectionFields = finalQuery.ProjectionFields.map((field: string) => "Items." + field);
    finalQuery.OrderByFields = ["-Items.Audit.CreatedAt", "-MaxOrderCreatedAt"]

    this.OrderShipments = (await this._orderService.AggregateSearchOrders(finalQuery, [groupPipe, postGroupFilterPipe]).toPromise()).Result as IPageResult<OrderShipmentItemReport>;

    this.buildOrderShipmentsIdMapping(this.OrderShipments.Items);
  }

  private buildOrderShipmentsIdMapping(groups: OrderShipmentItemReport[]) {

    this.OrderPackageCountChanged = [];
    groups.forEach(orderGroup => {
      orderGroup.Items.forEach(order => {
        var PreUnifyShipmentId = order.Shipments[0].PreUnifyShipment?.Id;
        var LeadingOrderId = orderGroup.Items.find(o => o.Shipments[0].Id == order.Shipments[0].Id && o.Shipments[0].PreUnifyShipment?.Id == o.Shipments[0].Id)?.Id;

        if (PreUnifyShipmentId && PreUnifyShipmentId != order.Shipments[0].Id) this.OrderIdMapping[order.Id] = LeadingOrderId;
        else delete this.OrderIdMapping[order.Id]
      });
    });

    this.buildOrderLeadingShipmentIds();
  }

  private buildOrderLeadingShipmentIds() {
    this.OrderLeadingIds = [];
    for (const key in this.OrderIdMapping) {
      const leadingId = this.OrderIdMapping[key];
      if (!this.OrderLeadingIds.includes(leadingId)) this.OrderLeadingIds.push(leadingId);
    }

    this.OrderShipments.Items.forEach(orderGroup => {
      orderGroup.HasUnification = this.IsGroupHaveUnification(orderGroup);
    });
  }

  private IsGroupHaveUnification(orderGroup: OrderShipmentItemReport) {

    for (let index = 0; index < orderGroup.Items.length; index++) {
      const order = orderGroup.Items[index];
      if (this.OrderLeadingIds.includes(order.Id)) return true;
    }

    return false;

  }


  public OnPackageCountChange(orderGroup: OrderShipmentItemReport, order: IOrder) {
    this.OrderPackageCountChanged.push(order.Id);
    orderGroup.HasChanges = true;
  }

  public Unify(orderGroup: OrderShipmentItemReport, order: IOrder, index: number, unUnify: boolean) {
    if (unUnify) {
      delete this.OrderIdMapping[order.Id];
    }
    else {
      this.OrderIdMapping[order.Id] = orderGroup.Items[index].Id;
      if (!this.OrderLeadingIds.includes(orderGroup.Items[index].Id)) this.OrderLeadingIds.push(orderGroup.Items[index].Id);
    }
    this.buildOrderLeadingShipmentIds();



    var actions = this.GetGroupSaveActions(orderGroup);
    orderGroup.HasChanges = !!((Object.keys(actions.UnifyShipments).length) || actions.UnUnifyShipments.length);
    orderGroup.HasUnification = this.IsGroupHaveUnification(orderGroup);
  }

  public SetOrderDateFilter(dateselection: DateSelectiontModel | null) {

    delete this.Query.AgeSeconds;
    delete this.Query.CreatedDateFrom;
    delete this.Query.CreatedDateTo;

    if (dateselection && dateselection.Age) this.Query.AgeSeconds = dateselection.Age;
    else if (dateselection && dateselection.Range) {
      this.Query.CreatedDateFrom = dateselection.Range.From;
      this.Query.CreatedDateTo = dateselection.Range.To;
    }

    this.reloadPage(true);
  }


  public reloadPage(resetPaging: boolean | undefined) {
    var query: IOrderSearchRequest = {};
    for (const key in this.Query) {
      if (key == "ProjectionFields") continue;
      if (key == "OrderByFields") {
        if ((this.Query.OrderByFields) && (this.DefaultQuery.OrderByFields) && (this.Query.OrderByFields[0] != this.DefaultQuery.OrderByFields[0])) query.OrderByFields = this.Query.OrderByFields;
        continue;
      };

      if (this.Query.hasOwnProperty(key)) {
        const element = this.Query[key];
        if (element == null) continue;

        if (Array.isArray(element)) {
          if (element.length > 0) query[key] = element.slice();
        }
        else {
          if ((element != this.DefaultQuery[key])) query[key] = element;
        }
      }
      if (this.Query[key] instanceof Date) {
        query[key] = this.Query[key].toISOString();
      }

    }
    if (resetPaging) {
      delete query.PageIndex;
    }


    query["timestamp"] = new Date().getTime();


    if (IsArrayEqual(this.DefaultQuery.ShipmentStatus, query.ShipmentStatus)) delete query.ShipmentStatus;
    if (IsArrayEqual(this.DefaultQuery.OrderState, query.OrderState)) delete query.OrderState;
    if (IsArrayEqual(this.DefaultQuery.NotOrderState, query.NotOrderState)) delete query.NotOrderState;

    this._router.navigate(["/orders-shipment-merger"], { queryParams: query });
  }


  public HTMLHelper = {

    IsLeadingShipment: (order: IOrder) => this.OrderLeadingIds.includes(order.Id),

    GetUnifyIndex: (orderGroup: OrderShipmentItemReport, order: IOrder) => {

      var bindOrderId = this.OrderIdMapping[order.Id] ?? order.Id;
      return orderGroup.Items.findIndex(o => o.Id == bindOrderId);

    },

    DateSelectiontModel: DateSelectiontModel,
    GenerateOrderGroupNumbersToUnify: (orderGroup: OrderShipmentItemReport, order: IOrder) => {
      var result: number[] = [];

      if (this.OrderLeadingIds.includes(order.Id)) {
        return [orderGroup.Items.findIndex(o => order.Shipments[0].Id == o.Shipments[0].Id)];
      }
      else {
        for (let index = 0; index < orderGroup.Items.length; index++) {
          if (!this.OrderIdMapping[orderGroup.Items[index].Id]) result.push(index);
        }
      }
      return result;
    },

    GetOrderSourceTypeFontAwesomeIcon: (order: IOrder) => {

      if (order.Source.Type & OrderSource.Excel) return 'fa-file-excel';
      else if (order.Source.Type & OrderSource.CSV) return 'fa-file-alt';
      else if (order.Source.Type & OrderSource.HTML) return 'fa-file-code';
      else if (order.Source.Type & OrderSource.Text) return 'fa-file-alt';
      else if (order.Source.Type & 1) return 'far-star';
      else return "";
    },

    GetOrderItemSKUDescription(order: IOrder): string {
      return order.Items.map(i => {
        var product = (i as IProductOrderItem).Product;
        if (product) return product.SKU;
        else return "";
      }
      ).filter(s => s && s.trim()).join("\n").trim();
    },
    GetOrderItemQuantity(order: IOrder): string {
      return order.Items.map(i =>
        (i as IProductOrderItem).Product ? i.Quantity.toString() : ""
      )
        .filter(s => s && s.trim()).join("\n").trim();
    },

    GetGroupSaveButHeight(group: OrderShipmentItemReport) {
      return group.Items.reduce((aggr, order) => aggr + order.Items.filter(item => (item as IProductOrderItem).Product).length, 0);
    }
  }


  private GetGroupSaveActions(group: OrderShipmentItemReport): {
    UnifyShipments: { [key: string]: { followingShipments: string[], PackagesCount: number } },
    UnUnifyShipments: string[],
    ShipmentPostUpdate: IShipment[],
  } {

    var UnifyShipments = {} as { [key: string]: { followingShipments: string[], PackagesCount: number } };
    var UnUnifyShipments = [] as string[];
    var ShipmentPostUpdate = [] as IShipment[];

    group.Items.forEach(order => {

      const dbShipId = order.Shipments[0].Id;
      const dbShipPreId = order.Shipments[0].PreUnifyShipment?.Id;

      const leadingOrderId = this.OrderIdMapping[order.Id];
      const newShipId = leadingOrderId ? group.Items.find(o => o.Id == leadingOrderId).Shipments[0].Id : null;

      if (newShipId != dbShipId && dbShipPreId && dbShipId != dbShipPreId) {
        UnUnifyShipments.push(dbShipId);
        UnUnifyShipments.push(dbShipPreId);
      }

      if (leadingOrderId && newShipId != dbShipId) {
        const dbComputePreID = dbShipPreId ?? dbShipId;
        if (UnifyShipments[newShipId]) UnifyShipments[newShipId].followingShipments.push(dbComputePreID);
        else UnifyShipments[newShipId] = {
          followingShipments: [dbComputePreID],
          PackagesCount: group.Items.find(o => o.Id == leadingOrderId).Shipments[0].PackagesCount
        }
      }

    });




    group.Items.forEach((order, index) => {
      if (this.OrderPackageCountChanged.includes(order.Id) && index == this.HTMLHelper.GetUnifyIndex(group, order)) {
        ShipmentPostUpdate.push(order.Shipments[0]);
      }
    });



    return {
      UnifyShipments,
      UnUnifyShipments,
      ShipmentPostUpdate
    };
  }


  public async SaveGroup(group: OrderShipmentItemReport) {

    group.Saving = true;

    var actions = this.GetGroupSaveActions(group);

    var savedOrders = [] as IOrder[];
    if (actions.UnUnifyShipments.length) {
      var UnUnifyOrderShipmentsResult = await this._orderService.UnUnifyOrderShipments(actions.UnUnifyShipments).toPromise();
      savedOrders = savedOrders.concat(UnUnifyOrderShipmentsResult.Result.Items);
    }

    if (Object.keys(actions.UnifyShipments).length) {
      for (const leadingId in actions.UnifyShipments) {
        if (Object.prototype.hasOwnProperty.call(actions.UnifyShipments, leadingId)) {
          const unifyShipmentsItem = actions.UnifyShipments[leadingId];
          var unifyOrderShipmentsResult = await this._orderService.UnifyOrderShipments(leadingId, unifyShipmentsItem.followingShipments).toPromise();
          savedOrders = savedOrders.concat(unifyOrderShipmentsResult.Result.Items);
        }
      }
    }


    await Promise.all(actions.ShipmentPostUpdate.map(ship => this._shipmentService.UpdateShipments([ship.Id], { PackagesCount: ship.PackagesCount }).toPromise()));

    this.reBindOrders(savedOrders);
    this.buildOrderShipmentsIdMapping([group]);

    group.Saving = false;
    group.HasChanges = false;
  }


  private reBindOrders(orders: IOrder[]) {
    orders.forEach(order => {

      var matchGroups = this.OrderShipments.Items.filter(group => group.Items.findIndex(o => o.Id == order.Id) >= 0);
      matchGroups.forEach(group => {

        var orderIndex = group.Items.findIndex(o => o.Id == order.Id);
        if (orderIndex >= 0) {
          group.Items[orderIndex] = $.extend(true, {}, order);
        }

      });
    });


  }
}

enum filterUnify {
  unifyShipmentOnly = 1,
  unUnifyShipmentOnly = 2,
}


interface OrderShipmentItemReport {
  "Key": {
    "City": string[],
    "PhoneHash": []
  },
  "Count": number,
  "Items": IOrder[],
  "Saving"?: boolean,
  "HasChanges"?: boolean
  "HasUnification"?: boolean

}
