import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService, ConvertObjectToQuery } from './common.service';

declare var $: any;
import { IApiResult } from '../models/api.model';
import { IProduct, IMasterAliasProductRelationship, IProductRef, IMasterProduct, IAliasProduct, IMasterMatchResult } from '../models/products.models';
import { IPageResult, ILongRunningOperation, IBaseQuery } from '../models/common.models';
import { StoreType } from '../models/stores';
import { UndefinedShipmentType } from '../models/shipments.models';



@Injectable()
export class LOVsService {

  constructor(private _common: CommonService, private _http: HttpClient) { }
  private _apiUrl = "/api/LOVs";

  public SearchCities(text: string): Observable<IApiResult<IPageResult<string>>> {
    return this._http.get<IApiResult<IPageResult<string>>>(this._apiUrl + '/SearchCities?text=' + encodeURIComponent(text));
  }
  public SearchStreet(city: string, text: string): Observable<IApiResult<IPageResult<string>>> {
    return this._http.get<IApiResult<IPageResult<string>>>(this._apiUrl + '/SearchStreet?city=' + encodeURIComponent(city) + '&text=' + encodeURIComponent(text));
  }
  public SearchShipmentSourceTypeValue(store: string, text: string, excludeNames: string[]): Observable<IApiResult<IPageResult<string>>> {
   
    return this._http.get<IApiResult<IPageResult<string>>>(this._apiUrl + '/SearchShipmentSourceTypeValue?' + ConvertObjectToQuery({
      store: store,
      text: text,
      excludeNames: excludeNames
    }));



  }
}
