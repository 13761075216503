import { Component, Input, Output, EventEmitter, OnInit, AfterViewChecked, AfterViewInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DeepCopyObject } from 'src/app/helpers/common.helper';
import { IDataTable, IExternalFile } from 'src/app/models/files.models';
import { IProductCatalog, IProductCatalogReferenceField, IProductCatalogTextField } from 'src/app/models/product.catalog';
import { FileService } from 'src/app/services/file.service';


@Component({
  selector: 'import-catalog-wizard',
  templateUrl: './importcatalogwizard.usercontrol.component.html',
  styleUrls: ['./importcatalogwizard.usercontrol.component.css']
})
export class ProductCatalogImportWizard implements OnInit, AfterViewInit {

  constructor(private _fileService: FileService) {

  }
  ngAfterViewInit(): void {
    this.StepChange.emit(WizardStep.fileUpload);
  }


  @Input()
  public Catalog: IProductCatalog;

  @Output()
  public CatalogChange: EventEmitter<IProductCatalog> = new EventEmitter<IProductCatalog>();

  @Output()
  public StepChange: EventEmitter<WizardStep> = new EventEmitter<WizardStep>();

  public UploadedFile: IExternalFile
  public DataTables: IDataTable<string>[] | null;
  public Step: WizardStep;
  public SelectedTableIndex: number;

  public fieldCandidates: catalogFieldCandidate[]
  ngOnInit() {

    this.StepChange.subscribe(step => {
      if (step == WizardStep.fieldSelect) this.loadFieldSelect();

    });
  }

  /*
SKU">מק"ט
UPC">מק"ט יצרן
Name">שם
Model">מודל
Category">קטגוריה
Media">תמונות
PriceList.RecommendedPrice">מחיר
Description">תיאור
Warranty">אחריות

  */

  private catalogExistingFields: { fieldRef: string, matchPattern: RegExp }[] = [
    { fieldRef: "SKU", matchPattern: /^sku$/i },
    { fieldRef: "UPC", matchPattern: /^ean$/i },
    { fieldRef: "Name", matchPattern: /^name$/i },
    { fieldRef: "Model", matchPattern: /^model$/i },
    { fieldRef: "Category", matchPattern: /^category$/i },

    { fieldRef: "Media", matchPattern: /^media\d?$/i },
    { fieldRef: "PriceList.RecommendedPrice", matchPattern: /^price$/i },

    { fieldRef: "Description", matchPattern: /^description$/i },
    { fieldRef: "Warranty", matchPattern: /^warranty-by$/i },
  ];

  public BuildCatalogFields(tableIndex) {
    this.SelectedTableIndex = tableIndex;
    this.fieldCandidates = [];

    for (let index = 0; index < this.DataTables[tableIndex].ColumnsNames.length; index++) {

      let record: catalogFieldCandidate = {
        Name: this.DataTables[tableIndex].ColumnsNames[index],
        Code: this.DataTables[tableIndex].Rows[0][index],
        Enable: true,
        ErrorMessage: ''
      };

      this.validateField(record);
      this.fieldCandidates.push(record);
    }
  }

  private validateField(field: catalogFieldCandidate) {

    var catalogField = this.Catalog.Fields.find(f => f.Code == field.Code);
    
    
    if (catalogField) {
      field.Enable = false;
      field.ErrorMessage += "\nשדה קיים בקטלוג הנוכחי"
    }

    if (this.catalogExistingFields.find(commonField => commonField.matchPattern.test(field.Code))){
      field.ErrorMessage += "\nשדה קיים כחלק משדות המוצר"
    }

    field.ErrorMessage = field.ErrorMessage.trim();
  }

  async loadFieldSelect() {
    this.DataTables = null;
    this.DataTables = (await firstValueFrom(this._fileService.ReadTableFile(this.UploadedFile))).Result;
    this.BuildCatalogFields(0);
  }



  public FileUploaded(files: IExternalFile[]) {
    this.UploadedFile = files[0];
  }
  public Cancel() {
    this.CatalogChange.emit(null);
  }

  public Save() {
    var newCatalog = DeepCopyObject(this.Catalog);

    this.fieldCandidates
      .filter(field => field.Enable)
      .forEach(field => {

        if (newCatalog.Fields.find(f => f.Code == field.Code)) return;

        const commonCatalogField = this.catalogExistingFields.find(commonField => commonField.matchPattern.test(field.Code));
        if (commonCatalogField) {
          newCatalog.Fields.push({
            Code: field.Code,
            Name: field.Name,
            ReferenceField: commonCatalogField.fieldRef,
            ItemType: 'ProductCatalogReferenceField'
          } as IProductCatalogReferenceField);
        }
        else {
          newCatalog.Fields.push({
            Code: field.Code,
            Name: field.Name,
            ItemType: 'ProductCatalogTextField'
          } as IProductCatalogTextField);
        }


      });

    this.CatalogChange.emit(newCatalog);
  }
}

interface catalogFieldCandidate {
  Name: string,
  Code: string,
  Enable: boolean,
  ErrorMessage: string

}
enum WizardStep {
  fileUpload = 1,
  fieldSelect = 2,
  finish = 3
}