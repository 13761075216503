import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDataTable, IExternalFile } from '../models/files.models';
import { IApiResult } from '../models/api.model';
import { IBaseQuery, IPageResult } from '../models/common.models';
import { ConvertObjectToQuery } from './common.service';


@Injectable()
export class FileService {
  private _apiUrl = "/api/Files";
  constructor(private _http: HttpClient) { }


  public UploadFiles(files: File[] | FileList): Observable<IApiResult<IExternalFile[]>> {
    let headers = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let formData: FormData = new FormData();
    for (var i = 0; i < files.length; i++) {
      let file: File = files[i];
      formData.append('uploadFile' + i, file, file.name);
    }

    return this._http.put<IApiResult<IExternalFile[]>>(this._apiUrl + '/UploadFiles', formData, {
      headers: headers
    });
  }
  public DownloadFile(file: IExternalFile, contentType?: string): void {
    if (file.Path) {
      this.DownloadUrl(file.Path, file.FileName ? file.FileName : "");
    }
  }
  public DownloadUrl(url: string, filename?: string): void {
    // window.location.href = url;
    /**/
    this._http.get(url, { responseType: 'blob' })
      .subscribe(fileData => {


        var url = window.URL.createObjectURL(fileData);

        var link = document.createElement("a");
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {

          link.setAttribute("href", url);
          link.setAttribute("download", filename ? filename : "");
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      );

  }

  public SearchFiles(query: IBaseQuery): Observable<IApiResult<IPageResult<IExternalFile>>> {
    return this._http.get<IApiResult<IPageResult<IExternalFile>>>(this._apiUrl + '/Search?' + ConvertObjectToQuery(query));
  }

  public ReadTableFile(file: IExternalFile): Observable<IApiResult<IDataTable<string>[]>> {
    return this._http.get<IApiResult<IDataTable<string>[]>>(this._apiUrl + '/ReadTableFile?id=' + encodeURIComponent(file.Id));
  }
}


