import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ShipmentService } from '../../../services/shipment.service';
import { ExternalSubmittableShipmentTypes, IShipmentType } from '../../../models/shipments.models';
import { ISupplierShipmentConfiguration } from '../../../models/suppliers';
import { AdminService } from '../../../services/admin.service';
import { TriggerValidation } from '../../../models/validation.models';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigStateFeature, WaitForConfigSave } from 'src/app/state/config.reducer';
import { Store } from '@ngrx/store';
import { ConfigSetSupplierShipmentConfiguration } from 'src/app/state/config.actions';
import { Actions } from '@ngrx/effects';
import { filter } from 'rxjs';
import { DeepCopyObject } from 'src/app/helpers/common.helper';
declare var $: any;


@Component({
  selector: 'app',
  templateUrl: './shipment.configuration.page.component.html',
  styleUrls: ['./shipment.configuration.page.component.css']
})
export class ShipmentConfigurationPageComponent implements OnInit, OnDestroy {


  constructor(
    private _adminService: AdminService,
    private _shipmentService: ShipmentService,
    private _route: ActivatedRoute,
    private store: Store,
    private actions$: Actions,
    private _router: Router) { }


  public ValidationFrom: UntypedFormGroup;

  public ShipmentType!: IShipmentType;
  public SupplierShipmentConfiguration!: ISupplierShipmentConfiguration;
  public HTMLTexts!: {
    LongDescription: string
    ShipmentsRemarksTemplateText?: string;
  };

  public ExternalSubmittableShipmentTypes: string[] = ExternalSubmittableShipmentTypes;
  ngOnInit() {
    this._route.params.subscribe(params => {
      this.store.select(ConfigStateFeature.selectShipmentTypes).pipe(filter(s => !!s)).subscribe(shipmenttypes => {
        this.ShipmentType = shipmenttypes[params['id']];

        this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)).subscribe(
          conf => {
            if (conf.Shipments && conf.Shipments[this.ShipmentType.Id]) this.SupplierShipmentConfiguration = DeepCopyObject(conf.Shipments[this.ShipmentType.Id]);
            else this.SupplierShipmentConfiguration = this._adminService.CreateNewSupplierShipmentConfiguration(params['id']);
            this.bindControls();
            // for debugging by uri
            window["ShipmentSetting"] = $.extend(true, {}, this.SupplierShipmentConfiguration);
          });

      });
    });

  }

  ngOnDestroy(): void {
    delete window["ShipmentSetting"];
  }

  public bindControls() {

    this.ValidationFrom = new UntypedFormGroup({
      SKU: new UntypedFormControl(this.SupplierShipmentConfiguration.ImportConfiguration.SKU,
        (control: AbstractControl) => {
          if (this.SupplierShipmentConfiguration.ExportConfiguration.EnableAddionLineInERPExport && (!control.value)) return { required: true };
          else return null;
        }),
      Description: new UntypedFormControl(this.SupplierShipmentConfiguration.ImportConfiguration.Description,
        (control: AbstractControl) => {
          if (this.SupplierShipmentConfiguration.ExportConfiguration.EnableAddionLineInERPExport && (!control.value)) return { required: true };
          else return null;
        }),
    });


    this.buildHTMLTexts();
  }

  private buildHTMLTexts() {

    switch (this.ShipmentType.Id) {
      case "BoxItShipment":
        this.HTMLTexts = {
          LongDescription: "בוקסאיט מאפשר ללקוחותיך איסוף של החבילות מארונות אלקטרונים במגוון נקודות בארץ ובנוסף  מאפשר שירות בוקסאיט איסוף החבילות ממאות נקודות איסוף הפזורות ברחבי הארץ.." +
            "\n\n" +
            "מערכת SendE מאפשרת לך להתחבר ישירות לשרות בוקסאיט עם פפרטי הלקוח שלך בבוקסאיט ולהפיק מדבקות הפצה ישירות מ-SendE ללא הצורך בכניסה למערכת בוקסאיט."
        }


        break;
      default:
        this.HTMLTexts = {
          LongDescription: ""
        }
        break;
    }

    this.HTMLTexts.ShipmentsRemarksTemplateText = "{{Shipments.Remarks}}";
  }

  public OnEnableAddionLineInERPExportChange() {
    this.RefreshValidity();
  }
  public RefreshValidity() {
    TriggerValidation(this.ValidationFrom);
  }
  public async SaveAndReturn() {
    this.OnEnableAddionLineInERPExportChange();


    if (this.ValidationFrom.valid) {



      if (await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierShipmentConfiguration({ idName: this.ShipmentType.Id, configuration: this.SupplierShipmentConfiguration })); })) {
        this._router.navigate(["/admin/shipment"]);
      }

    }
  }
}