import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { LOVsService } from '../../../services/LOVs.service';
import { Observable, of } from 'rxjs';
import { AbstractControl } from '@angular/forms';


@Component({
  selector: 'street-autocomplate-input',
  templateUrl: './street.autocomplate.input.usercontrol.component.html'
})
export class StreetAutoComplateInpotUserControlComponent implements OnInit {

  constructor(private _orderService: OrderService,
    private _LOVsService: LOVsService) { }

    

  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() model!: string;


  @Input() title!: string;

  @Input() City!: string;

  @Input() public bindFormControl!: AbstractControl ;
  @Input() public validationMessages !: { [key: string]: string };
  ngOnInit(): void {

    this.bindControls();

  }
  private bindControls() {

  }
  public RaiseChange() {
    this.modelChange.emit(this.model);
  }

  public SearchStreet = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((text => {
      if (text.length >= 2 && this.City) return this._LOVsService.SearchStreet(this.City, text);
      else return of(null);

    })),

    map(searchResults => {
      if (!searchResults) return [];
      var result = searchResults.Result.Items;
      return result;
    }
    ));

}

