import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';




@Component({
  selector: 'labeled-textbox',
  templateUrl: './labeledtextbox.usercontrol.component.html',
  styleUrls:['./labeledtextbox.usercontrol.component.css']
})
export class LabeledTextBoxUserControlComponent implements OnInit, OnChanges {

  constructor() {

  }

  @Input() title!: string;
  @Input() id!: string;
  @Input() type!: string;
  @Input() classes!: string;
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() model!: string;
  @Input() public tabindex: number = 0;

  @Input() public bindFormControl!: AbstractControl;
  @Input() public validationMessages !: { [key: string]: string };

  @Input() showValidIndicator!: boolean;
  @Input() updateOn?: 'change' | 'blur' | 'submit';

  @Input() readonly!: boolean;
  @Input() disabled!: boolean;
  
  // Function to call when the input is touched (when a star is clicked).

  ngOnInit() {
    if (!this.id) this.id = "id_" + Math.floor((Math.random() * 10000000000) + 1);
    if (!this.type) this.type = "text";
    if (!this.bindFormControl) this.bindFormControl = new UntypedFormControl(this.model, {
      updateOn: this.updateOn ?? "change"
    });

    this.bindFormControl.valueChanges.subscribe(v => this.modelChange.emit(v));




  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.bindFormControl && changes.model && changes.model.currentValue != changes.model.previousValue) this.bindFormControl.setValue(changes.model.currentValue);
  }


  public GetErrorMessage() {
    if (this.bindFormControl?.errors && this.validationMessages) {
      return this.validationMessages[Object.keys(this.bindFormControl.errors)[0]];
    }
    return "";
  }
}
