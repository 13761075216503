import { Component, Input, Output, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';


import { LabeledTextBoxUserControlComponent } from './labeledtextbox.usercontrol.component'
import { Observable, Subject, merge } from 'rxjs';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { NgbTypeaheadSelectItemEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, debounceTime, distinctUntilChanged, flatMap } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'labeled-autocomple-textbox',
  templateUrl: './labeledautocompletextbox.usercontrol.component.html',

})
export class LabeledAutoComplateTextBoxUserControlComponent extends LabeledTextBoxUserControlComponent
  implements OnInit {

  constructor() {
    super();
  }


  /**
  * The function that converts a stream of text values from the `<input>` element to the stream of the array of items
  * to display in the typeahead popup.
  *
  * If the resulting observable emits a non-empty array - the popup will be shown. If it emits an empty array - the
  * popup will be closed.
  *
  * See the [basic example](#/components/typeahead/examples#basic) for more details.
  *
  * Note that the `this` argument is `undefined` so you need to explicitly bind it to a desired "this" target.
  */
  @Input()
  public CngbTypeahead: (text: Observable<string>) => Observable<any[]>;

  /**
   * The function that converts an item from the result list to a `string` to display in the popup.
   *
   * Must be provided, if your `ngbTypeahead` returns something other than `Observable<string[]>`.
   *
   * Alternatively for more complex markup in the popup you should use `resultTemplate`.
   */
  @Input()
  public CresultFormatter: (item: any) => string;

  /**
   * The template to override the way resulting items are displayed in the popup.
   *
   * See the [ResultTemplateContext](#/components/typeahead/api#ResultTemplateContext) for the template context.
   *
   * Also see the [template for results demo](#/components/typeahead/examples#template) for more details.
   */
  @Input()
  public CresultTemplate: TemplateRef<ResultTemplateContext>;

  
  @Input()
  public Placement: string[] = ['bottom-right'];

  /**
   * If `true`, will show the hint in the `<input>` when an item in the result list matches.
   */
  @Input()
  public CshowHint: boolean;
  @Input()
  public OpenOnFocus: boolean;
  @Output()
  public CselectItem = new EventEmitter<NgbTypeaheadSelectItemEvent>();

  /**
   * The function that converts an item from the result list to a `string` to display in the `<input>` field.
   *
   * It is called when the user selects something in the popup or the model value changes, so the input needs to
   * be updated.
   */
  @Input()
  public CinputFormatter: (item: any) => string;

  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  @ViewChild('ngbTypeahead', {static: true}) instance: NgbTypeahead;

  public CngbTypeaheadInternal = (text$: Observable<string>) => {
    let debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
  
    if (this.OpenOnFocus){
      debouncedText$ = merge(debouncedText$, inputFocus$, clicksWithClosedPopup$);
    }

    return this.CngbTypeahead( debouncedText$);
  }


  public showDropdownEle(event) {

    if (event.keyCode === 38 || event.keyCode === 40) {
      if (event.target.nextElementSibling && event.target.nextElementSibling.nodeName === 'NGB-TYPEAHEAD-WINDOW') {
        let activeDropdownEle = (event.keyCode === 40) ? event.target.nextElementSibling.querySelector('.active').nextElementSibling : event.target.nextElementSibling.querySelector('.active').previousElementSibling;
        if (!activeDropdownEle) {
          const allDropdownElems = event.target.nextElementSibling.querySelectorAll('.dropdown-item');
          activeDropdownEle = (event.keyCode === 38) ? allDropdownElems[allDropdownElems.length - 1] : allDropdownElems[0];
        }

        let continerScrollTop = event.target.nextElementSibling.scrollTop;
        let continerHeight = event.target.nextElementSibling.offsetHeight;

        let activeItemTop = activeDropdownEle.offsetTop;
        let activeItemHeight = activeDropdownEle.offsetHeight;

        if (continerScrollTop > activeItemTop) {
          event.target.nextElementSibling.scrollTop = activeItemTop;
        } else if ((continerScrollTop + continerHeight) < activeItemTop + activeItemHeight) {
          event.target.nextElementSibling.scrollTop = activeItemTop + activeItemHeight - continerHeight;
        }

      }
    }
  }

}
